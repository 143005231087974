export const primary = '#3C3CCF';
export const secondary = '#F3733D';
export const accent = '#30604E';

export const dark = '#181818';

export const positive = '#00B06E';
export const lightPositive = '#ECFCED';
export const positiveTint = '#e0f8ec';

export const secondaryGreen = '#1a7468';
export const negative = '#DD4B53';
export const lightNegative = '#FCECEC';
export const info = '#6AADF1';
export const lightInfo = '#E7F4FD';
export const warning = '#F0A045';
export const lightWarning = '#FCF6EC';
export const light = '#ffffff';
export const link = '#5470F2';
export const lightPrimary = '#8A8AE2';
export const lighterPrimary = '#B1B1EC';

export const grey70 = '#646769';
export const grey50 = '#909294';
export const grey20 = '#D3D4D4';
export const grey10 = '#E7E9E9';
export const grey05 = '#F4F4F4';
export const grey02 = '#FBFBFB';
export const greige = '#FAF9F6';
export const marketingDark = '#2F2F2F';
export const blackish = '#22262A';
