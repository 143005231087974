import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@constants/date';
import { getAutomaticVerificationDetails, getAutomaticVerificationIssuerDetails } from '@api/automatic-verification';
import {
	getFirstTimeCheckoutDetails,
	saveCheckoutConfirmation as saveCheckoutConfirmationFromApi
} from '@api/checkout';
import {
	getIsInActiveBuyboxByCrunchbaseId,
	getIsInActiveBuybox as getIsInActiveBuyboxFromApi,
	getIssuer,
	submitIssuer as submitIssuerFromApi
} from '@api/legal-entity';
import { saveTaxpayerIdentificationNumber, submitPersonalIdentificationNumber } from '@api/identity';
import {
	SIGN_UP_AUTOMATIC_VERIFICATION_PIN_IDENTITY_STEPS_ORDER,
	SIGN_UP_AUTOMATIC_VERIFICATION_SSN_IDENTITY_STEPS_ORDER,
	SIGN_UP_STEPS_ORDER
} from '@constants/router';
import { StorageSerializers, useLocalStorage } from '@vueuse/core';
import {
	submitEquityCapture as submitEquityCaptureFromApi,
	updateEquityCapture as updateEquityCaptureFromApi
} from '@api/equity-capture';
import Big from 'big.js';
import dayjs from 'dayjs';
import { getImageUrl } from '@utils/crunchbase';
import { getSecurityIssuanceList } from '@api/security-issuance';
import { getUserSignUpFlowDeterminationDetails } from '@api/graphql/individual/user';
import { submitAcceptanceOfOffer as submitAcceptanceOfOfferFromApi } from '@api/offer-presentation';
import { submitAgreementsAcceptance } from '@api/agreement';
import { useUserStore } from '@store/user-store';
import type { AutomaticVerificationDetailsResponse } from 'types/api/automatic-verification';
import type { CheckoutDetailsResponse } from 'types/api/checkout';
import type { Company } from 'types/company/company';
import type { EquityCaptureResponse } from 'types/api/sign-up';
import type { RemovableRef } from '@vueuse/core';
import type { SecurityIssuance } from 'types/api/security-issuance';
import type { SignUpFlowType } from 'types/api/sign-up-flow';
import type { TransactionDetails } from 'types/store/sign-up-store';

export function emptyCompany(): Company {
	return {
		value: '',
		label: '',
		image: '',
		crunchbaseId: '',
		crunchbaseImageId: '',
		isInActiveBuyBox: false,
		isOnboarded: false
	};
}

export const useSignUpStore = defineStore('signUp', () => {
	const automaticVerificationEquityDetailsClickedContinue = useLocalStorage(
		'signUpStore:automaticVerificationEquityDetailsClickedContinue',
		false,
		{
			serializer: StorageSerializers.boolean
		}
	) as RemovableRef<boolean>;
	const automaticVerificationOfferAmount = ref<null | number>(null);
	const automaticVerificationNetProceeds = ref<null | number>(null);
	const automaticVerificationWantsToSellAnswerSelected = useLocalStorage(
		'signUpStore:automaticVerificationWantsToSellAnswerSelected',
		null,
		{
			serializer: StorageSerializers.boolean
		}
	) as RemovableRef<null | boolean>;
	const bankAccountId = ref<null | number | undefined>(null);
	const checkoutDetailsRetrieved = ref(false);
	const company = useLocalStorage('signUpStore:company', emptyCompany());
	const currentlyEmployed = useLocalStorage('signUpStore:currentlyEmployed', null, {
		serializer: StorageSerializers.boolean
	}) as RemovableRef<null | boolean>;
	const employmentId = ref<null | number>(null);
	const hasCompletedFirstTimeCheckout = ref<null | boolean>(null);
	const hasProvidedDateOfBirth = ref<null | boolean>(null);
	const hasProvidedPhoneNumber = ref<null | boolean>(null);
	const hasProvidedPin = ref<null | boolean>(null);
	const hasProvidedSsn = ref<null | boolean>(null);
	const lastDayOfEmployment = useLocalStorage('signUpStore:lastDayOfEmployment', null, {
		serializer: StorageSerializers.string
	}) as RemovableRef<null | string>;
	const maskedSsn = ref('');
	const numberOfSharesForAutomaticVerificationOffer = ref<null | number>(null);
	const pinSubmitted = ref(false);
	const planningToStay = useLocalStorage('signUpStore:planningToStay', null, {
		serializer: StorageSerializers.string
	}) as RemovableRef<null | string>;
	const previouslyEmployed = useLocalStorage('signUpStore:previouslyEmployed', null, {
		serializer: StorageSerializers.boolean
	}) as RemovableRef<null | boolean>;
	const retrievedEquityMappingIdentifier = ref(false);
	const retrievedIsInBuyBox = ref(false);
	const retrievedIsOnboarded = ref(false);
	const retrievedSecurityIssuances = ref(false);
	const securityIssuances = ref<Array<SecurityIssuance>>([]);
	const signUpFlowType = ref<SignUpFlowType | null>(null);
	const ssnSubmitted = ref(false);
	const transactionDetails = reactive<TransactionDetails>({
		alreadyExercised: null,
		currentPricePerShare: null,
		equitySubmissionId: null,
		fractionalShares: null,
		fractionalSharesVestedToDate: null,
		initialOfferAmount: null,
		maxInitialOfferAmount: 1000,
		numberOfSharesForOffer: null,
		offerPricePerShare: null,
		quantityExercised: null,
		secondaryTransactionId: null,
		secondaryTransactionStatus: '',
		securityClass: '',
		securityIssuanceId: null,
		strikePrice: null,
		securityType: null,
		unsupportedSecurityType: ''
	});

	const canUseAutomaticVerificationFlow = computed(() => {
		return signUpFlowType.value === 'AUTOMATIC_VERIFICATION' || Boolean(company.value.isOnboarded);
	});
	const hasFilledOutEmploymentQuestions = computed(() => {
		return !!(
			(currentlyEmployed.value !== null && planningToStay.value !== null) ||
			(!currentlyEmployed.value && previouslyEmployed.value && lastDayOfEmployment.value) ||
			(!currentlyEmployed.value && previouslyEmployed.value === false)
		);
	});
	const netProceedsPerShare = computed(() =>
		Big(transactionDetails.offerPricePerShare ?? 0)
			.sub(transactionDetails.strikePrice ?? 0)
			.toNumber()
	);
	const secondaryTransactionAndSecurityIssuancePresent = computed(() => {
		return (
			transactionDetails.secondaryTransactionId !== null &&
			transactionDetails.securityIssuanceId !== null &&
			transactionDetails.secondaryTransactionStatus === 'PRE_SUBMIT'
		);
	});
	const ssnOrPinProvided = computed(() => {
		return hasProvidedSsn.value || ssnSubmitted.value || hasProvidedPin.value || pinSubmitted.value;
	});
	const usedAutomaticVerificationFlow = computed(() => {
		return (
			canUseAutomaticVerificationFlow.value &&
			transactionDetails.secondaryTransactionId !== null &&
			transactionDetails.secondaryTransactionStatus === 'PRE_SUBMIT'
		);
	});
	const usedManualFirstTimeFlow = computed(() => {
		return Boolean(transactionDetails.equitySubmissionId);
	});
	const useSSNEquityMappingIdentifier = computed(() => {
		return company.value.equityMappingIdentifier === 'SSN';
	});
	const automaticVerificationSteps = computed(() => {
		if (useSSNEquityMappingIdentifier.value) {
			return SIGN_UP_AUTOMATIC_VERIFICATION_SSN_IDENTITY_STEPS_ORDER;
		} else {
			return SIGN_UP_AUTOMATIC_VERIFICATION_PIN_IDENTITY_STEPS_ORDER;
		}
	});
	// This computed value will determine whether we're in an automatic verification flow or regular sign-up flow and return
	// the corresponding steps array. It's basically shorthand for having a similar if check throughout the code in shared
	// sign-up routes, and it will let us use a single value in those places without having to check which flow we're in.
	const signUpSteps = computed(() => {
		let steps = [...SIGN_UP_STEPS_ORDER];
		if (canUseAutomaticVerificationFlow.value) {
			steps = automaticVerificationSteps.value;
		}

		return steps;
	});

	function clearValuesFromAPI() {
		bankAccountId.value = null;
		transactionDetails.currentPricePerShare = null;
		transactionDetails.equitySubmissionId = null;
		transactionDetails.initialOfferAmount = null;
		transactionDetails.numberOfSharesForOffer = null;
		transactionDetails.offerPricePerShare = null;
		transactionDetails.secondaryTransactionId = null;
		transactionDetails.secondaryTransactionStatus = '';
		transactionDetails.securityIssuanceId = null;
	}

	async function getCheckoutDetails() {
		const userStore = useUserStore();
		let savedDetails: CheckoutDetailsResponse | AutomaticVerificationDetailsResponse | null = null;
		if (!userStore.legalEntityId) {
			await userStore.getUserDetailsWithoutError();
		}

		await getIsOnboarded();

		if (userStore.legalEntityId) {
			await getSignUpFlowType();

			if (hasCompletedFirstTimeCheckout.value === null) {
				await getHasCompletedFirstTimeCheckout();
			}

			if (!hasCompletedFirstTimeCheckout.value) {
				if (canUseAutomaticVerificationFlow.value) {
					savedDetails = await getAutomaticVerificationDetails();

					if (
						savedDetails.securityIssuance?.secondaryTransactions &&
						savedDetails.securityIssuance?.secondaryTransactions.length === 1
					) {
						automaticVerificationOfferAmount.value =
							savedDetails.securityIssuance?.secondaryTransactions[0].amount;
						automaticVerificationNetProceeds.value = Big(
							savedDetails.securityIssuance?.secondaryTransactions[0].pricePerShare ?? 0
						)
							.sub(savedDetails.securityIssuance?.strikePrice ?? 0)
							.times(savedDetails.securityIssuance?.secondaryTransactions[0].fractionalShares ?? 0)
							.toNumber();
						numberOfSharesForAutomaticVerificationOffer.value =
							savedDetails.securityIssuance?.secondaryTransactions[0].fractionalShares;
					}
				} else {
					savedDetails = await getFirstTimeCheckoutDetails();
				}

				if (savedDetails) {
					checkoutDetailsRetrieved.value = true;
					if (savedDetails.securityIssuance) {
						if (savedDetails.securityIssuance.issuer) {
							company.value = {
								label: savedDetails.securityIssuance.issuer.name,
								value: savedDetails.securityIssuance.issuer.name,
								crunchbaseId: savedDetails.securityIssuance.issuer.crunchbaseId,
								crunchbaseImageId: savedDetails.securityIssuance.issuer.crunchbaseImageId,
								image: getImageUrl(savedDetails.securityIssuance.issuer.crunchbaseImageId),
								legalEntityId: savedDetails.securityIssuance.issuer.legalEntityId,
								isInActiveBuyBox: false,
								isOnboarded: company.value.isOnboarded ?? false
							};
							await getIsInActiveBuybox(true);
							if (company.value.isOnboarded) {
								await getEquityMappingIdentifier(true);
							}
						}
						if (savedDetails.securityIssuance.employment) {
							currentlyEmployed.value = savedDetails.securityIssuance.employment.currentlyEmployed;
							previouslyEmployed.value = savedDetails.securityIssuance.employment.previouslyEmployed;
							planningToStay.value = savedDetails.securityIssuance.employment.planningToStay;
							if (savedDetails.securityIssuance.employment.employmentEndDate) {
								lastDayOfEmployment.value = dayjs(
									savedDetails.securityIssuance.employment.employmentEndDate,
									SERVER_DATE_FORMAT,
									true
								).format(DATE_FORMAT);
							} else {
								lastDayOfEmployment.value = null;
							}
						}
					}
					hasProvidedDateOfBirth.value = savedDetails.hasProvidedDateOfBirth ?? false;
					if (!maskedSsn.value) {
						hasProvidedSsn.value = savedDetails.hasProvidedSsn ?? false;
					}
					hasProvidedPin.value = savedDetails.hasProvidedPin ?? false;
					userStore.setCheckoutDetails(savedDetails);
					hasProvidedPhoneNumber.value = !!userStore.phoneNumber;

					setTransactionDetails(savedDetails);
				}
			}
		}
	}

	async function getEquityMappingIdentifier(forceUpdate?: boolean) {
		if (
			company.value.label &&
			company.value.legalEntityId &&
			(!retrievedEquityMappingIdentifier.value || forceUpdate)
		) {
			const { equityMappingIdentifier } = await getAutomaticVerificationIssuerDetails();
			company.value.equityMappingIdentifier = equityMappingIdentifier;
			retrievedEquityMappingIdentifier.value = true;
		}
	}

	async function getHasCompletedFirstTimeCheckout() {
		const userStore = useUserStore();
		if (userStore.legalEntityId === null) {
			try {
				await userStore.getUserDetails();
			} catch (error) {
				hasCompletedFirstTimeCheckout.value = false;
			}
		}
		if (userStore.legalEntityId !== null) {
			try {
				await getSignUpFlowType();
				hasCompletedFirstTimeCheckout.value = signUpFlowType.value === 'DASHBOARD';
			} catch (error) {
				hasCompletedFirstTimeCheckout.value = false;
			}
		}
	}

	async function getIsOnboarded(forceUpdate?: boolean) {
		if (company.value.label && company.value.crunchbaseId && (!retrievedIsOnboarded.value || forceUpdate)) {
			const issuer = await getIssuer(company.value.crunchbaseId);
			company.value.isOnboarded = issuer.isOnboarded;
			retrievedIsOnboarded.value = true;
			if (!company.value.legalEntityId) {
				company.value.legalEntityId = issuer.legalEntityId;
			}
		}
	}

	async function getIsInActiveBuybox(forceUpdate?: boolean) {
		if (company.value.label && (!retrievedIsInBuyBox.value || forceUpdate)) {
			if (company.value.legalEntityId) {
				company.value.isInActiveBuyBox = await getIsInActiveBuyboxFromApi();
				retrievedIsInBuyBox.value = true;
			} else if (company.value.crunchbaseId) {
				company.value.isInActiveBuyBox = await getIsInActiveBuyboxByCrunchbaseId();
				retrievedIsInBuyBox.value = true;
			}
		}
	}

	async function getSecurityIssuances(forceUpdate?: boolean, verificationStatuses = ['VERIFIED']) {
		const userStore = useUserStore();
		if (
			userStore.legalEntityId &&
			(ssnSubmitted.value || hasProvidedSsn.value || pinSubmitted.value || hasProvidedPin.value) &&
			(!retrievedSecurityIssuances.value || forceUpdate)
		) {
			securityIssuances.value = (await getSecurityIssuanceList(verificationStatuses)).securityIssuances;
			retrievedSecurityIssuances.value = true;

			if (company.value.legalEntityId) {
				// Filter out security issuances that don't match the selected company
				securityIssuances.value = securityIssuances.value.filter(
					(issuance) => issuance.issuer?.legalEntityId === company.value.legalEntityId
				);
			} else if (
				!company.value.label &&
				securityIssuances.value.length > 0 &&
				securityIssuances.value[0].issuer
			) {
				const issuer = securityIssuances.value[0].issuer;
				company.value = {
					label: issuer.name,
					value: issuer.name,
					crunchbaseId: issuer.crunchbaseId,
					crunchbaseImageId: issuer.crunchbaseImageId,
					image: getImageUrl(issuer.crunchbaseImageId),
					legalEntityId: issuer.legalEntityId
				};
			}
		}
	}

	async function getSignUpFlowType(forceUpdate?: boolean) {
		if (!signUpFlowType.value || forceUpdate) {
			const signUpFlowDeterminationDetails = await getUserSignUpFlowDeterminationDetails();
			if (!signUpFlowDeterminationDetails.hasSecurityIssuances) {
				signUpFlowType.value = 'COMPANY_SELECT';
			} else if (signUpFlowDeterminationDetails.hasPostCheckoutSecondaryTransactions) {
				signUpFlowType.value = 'DASHBOARD';
			} else if (signUpFlowDeterminationDetails.hasCompanyControlSeller) {
				signUpFlowType.value = 'AUTOMATIC_VERIFICATION';
			} else {
				signUpFlowType.value = 'FIRST_TIME_SIGN_UP';
			}
		}
	}

	async function hasNoSecurityIssuances(forceUpdate?: boolean) {
		await getSecurityIssuances(forceUpdate);
		return securityIssuances.value.length === 0;
	}

	function resetCompany() {
		checkoutDetailsRetrieved.value = false;
		company.value = emptyCompany();
		retrievedIsInBuyBox.value = false;
		retrievedIsOnboarded.value = false;
		currentlyEmployed.value = null;
		planningToStay.value = null;
		previouslyEmployed.value = null;
		lastDayOfEmployment.value = null;
		employmentId.value = null;
	}

	function resetLocalStorageValues() {
		resetCompany();
		automaticVerificationEquityDetailsClickedContinue.value = false;
		automaticVerificationWantsToSellAnswerSelected.value = null;
	}

	function resetTransactionDetails() {
		transactionDetails.alreadyExercised = null;
		transactionDetails.currentPricePerShare = null;
		transactionDetails.equitySubmissionId = null;
		transactionDetails.fractionalShares = null;
		transactionDetails.fractionalSharesVestedToDate = null;
		transactionDetails.initialOfferAmount = null;
		transactionDetails.maxInitialOfferAmount = 1000;
		transactionDetails.numberOfSharesForOffer = null;
		transactionDetails.offerPricePerShare = null;
		transactionDetails.quantityExercised = null;
		transactionDetails.secondaryTransactionId = null;
		transactionDetails.secondaryTransactionStatus = '';
		transactionDetails.securityClass = '';
		transactionDetails.securityIssuanceId = null;
		transactionDetails.strikePrice = null;
		transactionDetails.securityType = null;
		transactionDetails.unsupportedSecurityType = '';
	}

	async function saveCheckoutConfirmation(documentAcknowledgments: number[], acknowledgmentAcceptances: number[]) {
		const secondaryTransactionId = transactionDetails.secondaryTransactionId!;
		await submitAgreementsAcceptance(secondaryTransactionId, documentAcknowledgments, acknowledgmentAcceptances);
		await saveCheckoutConfirmationFromApi(secondaryTransactionId);
		hasCompletedFirstTimeCheckout.value = true;
	}

	function saveEquityCaptureValues(equityCaptureResponse: EquityCaptureResponse) {
		if (equityCaptureResponse.secondaryTransaction) {
			transactionDetails.offerPricePerShare = equityCaptureResponse.secondaryTransaction.pricePerShare;
			transactionDetails.numberOfSharesForOffer = equityCaptureResponse.secondaryTransaction.fractionalShares;
			transactionDetails.initialOfferAmount = equityCaptureResponse.secondaryTransaction.amount;
			transactionDetails.secondaryTransactionId =
				equityCaptureResponse.secondaryTransaction.secondaryTransactionId;
			transactionDetails.secondaryTransactionStatus = equityCaptureResponse.secondaryTransaction.status;
		}
	}

	async function saveEmployeeIdentificationNumber(ein: string | null, equityMappingIdentifier: 'SSN' | 'PIN') {
		const userStore = useUserStore();
		let attemptsRemaining: number;
		if (userStore.legalEntityId && ein) {
			if (equityMappingIdentifier === 'SSN') {
				attemptsRemaining = (await saveTaxpayerIdentificationNumber(ein)).attemptsRemaining;
				ssnSubmitted.value = true;
			} else {
				attemptsRemaining = (await submitPersonalIdentificationNumber(ein)).attemptsRemaining;
				pinSubmitted.value = true;
			}
			return attemptsRemaining;
		} else {
			return Promise.reject(
				new Error(
					`Error saving ${
						equityMappingIdentifier === 'SSN'
							? 'taxpayer identification number'
							: 'personal identification number'
					}`
				)
			);
		}
	}

	function setTransactionDetails(checkoutDetails: CheckoutDetailsResponse | AutomaticVerificationDetailsResponse) {
		if (checkoutDetails.securityIssuance) {
			transactionDetails.fractionalShares = checkoutDetails.securityIssuance.fractionalShares;
			transactionDetails.fractionalSharesVestedToDate =
				checkoutDetails.securityIssuance.fractionalSharesVestedToDate;
			transactionDetails.strikePrice = checkoutDetails.securityIssuance.strikePrice;
			transactionDetails.securityIssuanceId = checkoutDetails.securityIssuance.securityIssuanceId as number;
			transactionDetails.alreadyExercised = !!checkoutDetails.securityIssuance.exercisedShares;
			transactionDetails.quantityExercised = checkoutDetails.securityIssuance.exercisedShares ?? null;
			if (checkoutDetails.securityIssuance.equityCaptureDetails) {
				transactionDetails.currentPricePerShare =
					checkoutDetails.securityIssuance.equityCaptureDetails.currentPricePerShare ?? null;
				transactionDetails.securityType = checkoutDetails.securityIssuance.equityCaptureDetails.securityType;
				transactionDetails.securityClass = checkoutDetails.securityIssuance.equityCaptureDetails.securityClass;
				transactionDetails.equitySubmissionId =
					checkoutDetails.securityIssuance.equityCaptureDetails.equitySubmissionId ?? null;
			}
		}

		let secondaryTransaction = checkoutDetails.secondaryTransaction;
		if (
			checkoutDetails.securityIssuance?.secondaryTransactions &&
			checkoutDetails.securityIssuance?.secondaryTransactions?.length === 1
		) {
			secondaryTransaction = checkoutDetails.securityIssuance.secondaryTransactions[0];
		}

		if (secondaryTransaction) {
			bankAccountId.value = secondaryTransaction.bankAccountId;
			transactionDetails.initialOfferAmount = secondaryTransaction.amount;
			transactionDetails.numberOfSharesForOffer = secondaryTransaction.fractionalShares;
			transactionDetails.offerPricePerShare = secondaryTransaction.pricePerShare;
			transactionDetails.secondaryTransactionId = secondaryTransaction.secondaryTransactionId;
			transactionDetails.secondaryTransactionStatus = secondaryTransaction.status;
		}
	}

	async function submitAcceptanceOfOffer(numberOfSharesForOffer?: number, offerAmount?: number) {
		if (transactionDetails.secondaryTransactionId) {
			const response = await submitAcceptanceOfOfferFromApi(
				transactionDetails.secondaryTransactionId,
				Number(numberOfSharesForOffer ?? transactionDetails.numberOfSharesForOffer),
				Number(offerAmount ?? transactionDetails.initialOfferAmount)
			);
			transactionDetails.secondaryTransactionId = response.secondaryTransactionId;
			transactionDetails.secondaryTransactionStatus = response.secondaryTransactionStatus;
		}
	}

	async function submitEquityCapture() {
		const equityCaptureResponse = await submitEquityCaptureFromApi();
		transactionDetails.securityIssuanceId = equityCaptureResponse.securityIssuance.securityIssuanceId;
		transactionDetails.equitySubmissionId = equityCaptureResponse.equitySubmission.equitySubmissionId;
		saveEquityCaptureValues(equityCaptureResponse);
		employmentId.value = equityCaptureResponse.employment.employmentId;
	}

	async function submitIssuer() {
		const response = await submitIssuerFromApi();
		company.value.legalEntityId = response.legalEntityId;
	}

	async function updateEquityCapture() {
		const equityCaptureResponse = await updateEquityCaptureFromApi();
		saveEquityCaptureValues(equityCaptureResponse);
	}

	return {
		automaticVerificationEquityDetailsClickedContinue,
		automaticVerificationOfferAmount,
		automaticVerificationNetProceeds,
		automaticVerificationWantsToSellAnswerSelected,
		automaticVerificationSteps,
		bankAccountId,
		checkoutDetailsRetrieved,
		company,
		currentlyEmployed,
		employmentId,
		hasCompletedFirstTimeCheckout,
		hasProvidedDateOfBirth,
		hasProvidedPhoneNumber,
		hasProvidedPin,
		hasProvidedSsn,
		lastDayOfEmployment,
		maskedSsn,
		netProceedsPerShare,
		numberOfSharesForAutomaticVerificationOffer,
		pinSubmitted,
		planningToStay,
		previouslyEmployed,
		retrievedEquityMappingIdentifier,
		retrievedIsInBuyBox,
		retrievedIsOnboarded,
		retrievedSecurityIssuances,
		securityIssuances,
		signUpFlowType,
		ssnSubmitted,
		transactionDetails,
		canUseAutomaticVerificationFlow,
		hasFilledOutEmploymentQuestions,
		secondaryTransactionAndSecurityIssuancePresent,
		signUpSteps,
		ssnOrPinProvided,
		usedAutomaticVerificationFlow,
		usedManualFirstTimeFlow,
		useSSNEquityMappingIdentifier,
		clearValuesFromAPI,
		getCheckoutDetails,
		getEquityMappingIdentifier,
		getHasCompletedFirstTimeCheckout,
		getIsOnboarded,
		getIsInActiveBuybox,
		getSecurityIssuances,
		getSignUpFlowType,
		hasNoSecurityIssuances,
		resetCompany,
		resetLocalStorageValues,
		resetTransactionDetails,
		saveCheckoutConfirmation,
		saveEmployeeIdentificationNumber,
		submitAcceptanceOfOffer,
		submitEquityCapture,
		submitIssuer,
		updateEquityCapture
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useSignUpStore as any, import.meta.hot));
}
