import { useAppStore } from '@store/app-store';
import type { Alert, AlertTypeEnum } from 'types/alerts';

export function displayAlert(
	type: AlertTypeEnum,
	message = 'An error has occurred. The error has been logged and we will look into it immediately.',
	showDismissButton = true
): void {
	const alert: Alert = {
		type,
		message,
		showDismissButton
	};
	const appStore = useAppStore();
	appStore.addAlert(alert);
}
