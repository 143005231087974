export function initAccessibe(): void {
	window.acsbJS?.init({
		statementLink: '',
		footerHtml: '',
		hideMobile: false,
		hideTrigger: false,
		language: 'en',
		position: 'left',
		leadColor: '#3C3CCF',
		triggerColor: '#3C3CCF',
		triggerRadius: '50%',
		triggerPositionX: 'right',
		triggerPositionY: 'bottom',
		triggerIcon: 'display3',
		triggerSize: 'medium',
		triggerOffsetX: 20,
		triggerOffsetY: 20,
		mobile: {
			triggerSize: 'small',
			triggerPositionX: 'right',
			triggerPositionY: 'bottom',
			triggerOffsetX: 10,
			triggerOffsetY: 10,
			triggerRadius: '50%'
		}
	});
}
