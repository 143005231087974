import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from 'vue-router';
import { authGuard } from '@auth';
import { pageViewed } from '@utils/analytics';
import { SIGN_UP_STEPS_ORDER } from '@constants/router';
import { useAppStore } from '@store/app-store';
import { useLogger } from '@utils/composables/useLogger';
import { useSignUpStore } from '@store/sign-up-store';
import type { Router, RouteRecordRaw } from 'vue-router';

const { routerLogger } = useLogger('router');

let router: Router;

const routerPromise: Promise<Router> = new Promise((resolve) => {
	function initializeRouter(routes: RouteRecordRaw[]) {
		router = createRouter({
			history: createWebHistory(),
			routes
		});

		router.beforeEach(async (to, from, next) => {
			const appStore = useAppStore();

			pageViewed(to.name?.toString() ?? '', { from: from.name });

			if (!to.matched.length) {
				next('/404');
			} else {
				appStore.updateRouteTransition({ to, from });

				if (!to.meta.auth) {
					next();
				} else {
					try {
						const failure = await authGuard(to, from, next);
						if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
							routerLogger.warn('Auth required. Navigation to /login...');
						}
					} catch (error) {
						routerLogger.warn(`Router error: ${error}`);
					}
				}
			}
		});

		router.afterEach(() => {
			window.scroll(0, 0);
		});

		resolve(router);
	}

	if (process.env.VITE_SENTRY_ENVIRONMENT === 'production') {
		import('./routes')
			.then((module) => {
				initializeRouter(module.routes);
			})
			.catch(() => {});
	} else {
		import('./routes-next')
			.then((module) => {
				initializeRouter(module.routes);
			})
			.catch(() => {});
	}
});

export function back(): void {
	router.back();
}

export async function signUpBack(): Promise<void> {
	const signUpStore = useSignUpStore();
	if (signUpStore.canUseAutomaticVerificationFlow) {
		await doAutomaticVerificationSignUpBack();
	} else {
		await doSelfReportedSignUpBack();
	}
}

async function doSelfReportedSignUpBack(): Promise<void> {
	const step = SIGN_UP_STEPS_ORDER.indexOf(router.currentRoute.value.name?.toString() ?? '');
	if (step && Number(step) > 0) {
		await router.push({ name: SIGN_UP_STEPS_ORDER[step - 1] });
	} else {
		back();
	}
}

async function doAutomaticVerificationSignUpBack(): Promise<void> {
	const signUpStore = useSignUpStore();
	const currentRouteName = router.currentRoute.value.name?.toString();

	if (currentRouteName === 'sign-up-no-equity') {
		await router.push({ name: 'sign-up-identity' });
	} else {
		const step = signUpStore.automaticVerificationSteps.indexOf(currentRouteName ?? '');
		if (step && Number(step) > 0) {
			await router.push({ name: signUpStore.automaticVerificationSteps[step - 1] });
		} else {
			back();
		}
	}
}

export default routerPromise;
