import { displayAlert } from '@utils/alerts';
import type { AxiosError } from 'axios';
import type { CombinedError } from 'villus';

export function redirectToLoginOrShowErrorAlert(
	err: AxiosError | CombinedError,
	showErrorAlert = true,
	alertMessage?: string
) {
	const accessDeniedGraphQL =
		(err as CombinedError)?.isGraphQLError &&
		(err as CombinedError).graphqlErrors?.some((error) => error.message.toLowerCase().includes('access denied'));

	const isMaxNumberOfAttemptsError = (
		((err as AxiosError)?.response?.data as any)?.errorDescription ?? ''
	).startsWith('maximum number of attempts exceeded');

	if (err.response?.status === 401 || accessDeniedGraphQL) {
		window.location.href = '/login';
	} else if (showErrorAlert && !isMaxNumberOfAttemptsError) {
		displayAlert('negative', alertMessage);
	}
}

export async function errorHandler(err: AxiosError, showErrorAlert = true, alertMessage?: string): Promise<unknown> {
	redirectToLoginOrShowErrorAlert(err, showErrorAlert, alertMessage);
	return Promise.reject(err);
}
