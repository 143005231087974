import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@constants/date';
import Big from 'big.js';
import dayjs from 'dayjs';
import { privateApiRequest } from '@utils/request';
import { useSignUpStore } from '@store/sign-up-store';
import { useUserStore } from '@store/user-store';
import type { EquityCaptureResponse } from 'types/api/sign-up';

function getEquityCaptureData() {
	const signUpStore = useSignUpStore();

	return {
		equitySubmission: {
			currentPricePerShare: signUpStore.transactionDetails.currentPricePerShare
				? new Big(signUpStore.transactionDetails.currentPricePerShare).toString()
				: null,
			issuerLegalEntityId: signUpStore.company.legalEntityId,
			securityClass: signUpStore.transactionDetails.securityClass,
			securityType: signUpStore.transactionDetails.securityType
		},
		securityIssuance: {
			fractionalShares: signUpStore.transactionDetails.fractionalShares,
			fractionalSharesVestedToDate: signUpStore.transactionDetails.fractionalSharesVestedToDate,
			strikePrice: signUpStore.transactionDetails.strikePrice
				? new Big(signUpStore.transactionDetails.strikePrice).toString()
				: null,
			exercisedShares: signUpStore.transactionDetails.quantityExercised
				? new Big(signUpStore.transactionDetails.quantityExercised).toString()
				: null
		},
		employment: {
			currentlyEmployed: signUpStore.currentlyEmployed,
			previouslyEmployed: signUpStore.previouslyEmployed,
			lastDayOfEmployment: signUpStore.lastDayOfEmployment
				? dayjs(signUpStore.lastDayOfEmployment, DATE_FORMAT, true).format(SERVER_DATE_FORMAT)
				: null,
			planningToStay: signUpStore.planningToStay
		}
	};
}

export async function submitEquityCapture(): Promise<EquityCaptureResponse> {
	const userStore = useUserStore();

	const response = await privateApiRequest.post(
		`/equity-capture/legal-entity/${userStore.legalEntityId}/submit`,
		getEquityCaptureData()
	);
	return response.data;
}

export async function updateEquityCapture(): Promise<EquityCaptureResponse> {
	const userStore = useUserStore();
	const signUpStore = useSignUpStore();

	const response = await privateApiRequest.put(
		`/equity-capture/legal-entity/${userStore.legalEntityId}/security-issuance/${signUpStore.transactionDetails.securityIssuanceId}/save`,
		getEquityCaptureData()
	);
	return response.data;
}

export async function submitUnsupportedEquityFeedback(): Promise<void> {
	const userStore = useUserStore();
	const signUpStore = useSignUpStore();
	await privateApiRequest.post(`/equity-capture/legal-entity/${userStore.legalEntityId}/unsupported-equity/submit`, {
		unsupportedSecurityType: signUpStore.transactionDetails.unsupportedSecurityType,
		sharesIssued: signUpStore.transactionDetails.fractionalShares,
		sharesVestedToDate: signUpStore.transactionDetails.fractionalSharesVestedToDate,
		issuerLegalEntityId: signUpStore.company.legalEntityId
	});
}
