import axios from 'axios';
import { csrfInterceptor } from '@utils/interceptors/csrf-interceptor';
import { errorHandler } from '@shared/utils/interceptors/error-handler';
import type { Toast } from 'types/layout';

declare module 'axios' {
	export interface AxiosRequestConfig {
		errorToast?: Toast;
		suppressToast?: (response: AxiosResponse) => boolean;
	}
}

export const privateApiRequest = axios.create({
	baseURL: process.env.VITE_PRIVATE_API_BASE_PATH,
	withCredentials: true
});
privateApiRequest.interceptors.request.use(
	(config) => csrfInterceptor(config),
	(error) => Promise.reject(error)
);
privateApiRequest.interceptors.response.use(
	(response) => response,
	(error) => errorHandler(error)
);

export const privateApiRequestWithoutErrorAlert = axios.create({
	baseURL: process.env.VITE_PRIVATE_API_BASE_PATH,
	withCredentials: true
});
privateApiRequestWithoutErrorAlert.interceptors.request.use(
	(config) => csrfInterceptor(config),
	(error) => Promise.reject(error)
);
privateApiRequestWithoutErrorAlert.interceptors.response.use(
	(response) => response,
	(error) => errorHandler(error, false)
);

export const privateApiRequestWithoutLoginRedirect = axios.create({
	baseURL: process.env.VITE_PRIVATE_API_BASE_PATH,
	withCredentials: true
});
privateApiRequestWithoutLoginRedirect.interceptors.request.use(
	(config) => csrfInterceptor(config),
	(error) => Promise.reject(error)
);

export const publicApiRequest = axios.create({
	baseURL: process.env.VITE_PUBLIC_API_BASE_PATH,
	withCredentials: false
});
publicApiRequest.interceptors.response.use(
	(response) => response,
	(error) => errorHandler(error)
);

export const publicApiRequestWithoutErrorAlert = axios.create({
	baseURL: process.env.VITE_PUBLIC_API_BASE_PATH,
	withCredentials: false
});
publicApiRequestWithoutErrorAlert.interceptors.response.use(
	(response) => response,
	(error) => errorHandler(error, false)
);
