export async function loadSentryReplay() {
	if (process.env.VITE_SENTRY_ENVIRONMENT === 'production') {
		const { getCurrentHub, Replay } = await import('@sentry/vue');
		// @ts-expect-error TypeScript doesn't like this
		getCurrentHub()
			.getClient()!
			.addIntegration(
				new Replay({
					maskAllText: true,
					blockAllMedia: true
				})
			);
	}
}
