import axios from 'axios';
import type { CsrfTokenResponse } from 'types/api/user';

export async function getCsrfToken(): Promise<CsrfTokenResponse> {
	const axiosInstance = axios.create({
		baseURL: process.env.VITE_PRIVATE_API_BASE_PATH,
		withCredentials: true
	});
	const response = await axiosInstance.get(`/identity/csrf`);
	return response.data;
}
