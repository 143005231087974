// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';

// Animations from Animate.css:
import '@quasar/extras/animate/fadeIn.css';
import '@quasar/extras/animate/fadeOut.css';

import './styles/style.scss';

import {
	accent,
	dark,
	greige,
	grey02,
	grey05,
	grey10,
	grey20,
	grey50,
	grey70,
	info,
	light,
	lightInfo,
	lightNegative,
	lightPositive,
	lightWarning,
	link,
	negative,
	positive,
	positiveTint,
	primary,
	secondary,
	secondaryGreen,
	warning
} from './colors';
import { Dialog, Loading, Meta, Notify, Quasar, setCssVar } from 'quasar';

import 'uno.css';
import App from './App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { getVillusClient } from '@utils/villus';
import { initializeNonCriticalPathDependencies } from '@utils/initializer';
import routerPromise from '@router';
import type { Router } from 'vue-router';

declare global {
	interface Window {
		ga: any;
		google: any;
		initMap: any;
		mixpanel: any;
		Plaid: any;
		acsbJS?: any;
	}
}

// Create Vue app
const app = createApp(App);

// setup and use Quasar
app.use(Quasar, {
	plugins: { Dialog, Loading, Meta, Notify },
	config: {
		brand: {
			accent,
			dark,
			greige,
			'grey-02': grey02,
			'grey-05': grey05,
			'grey-10': grey10,
			'grey-20': grey20,
			'grey-50': grey50,
			'grey-70': grey70,
			info,
			light,
			'light-info': lightInfo,
			'light-negative': lightNegative,
			'light-positive': lightPositive,
			'light-warning': lightWarning,
			link,
			negative,
			positive,
			'positive-tint': positiveTint,
			primary,
			secondary,
			'secondary-green': secondaryGreen,
			warning
		}
	}
});

setCssVar('grey-10', grey10);
setCssVar('grey-20', grey20);

// use pinia for state management
const pinia = createPinia();
app.use(pinia);

// use Villus for GraphQL
app.use(getVillusClient());

routerPromise
	.then((router: Router) => {
		// use router
		app.use(router);

		router
			.isReady()
			.then(() => app.mount('#app'))
			.catch(() => {})
			.finally(() => {
				initializeNonCriticalPathDependencies(app, router);
			});
	})
	.catch(() => {});
