/* eslint-disable prefer-rest-params */
// eslint-disable-next-line ts/ban-ts-comment
// @ts-nocheck
export function initGoogleTag(): void {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());

	gtag('config', process.env.VITE_GOOGLE_ANALYTICS_MEASURE_ID);
}
