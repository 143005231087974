import { DATE_FORMAT, SERVER_DATE_FORMAT } from '@constants/date';

import { privateApiRequest, privateApiRequestWithoutErrorAlert } from '@utils/request';
import dayjs from 'dayjs';
import { useUserStore } from '@store/user-store';
import type { AxiosInstance } from 'axios';
import type { PhoneNumberResponse } from 'types/api/phone-number';

export async function saveDateOfBirth(): Promise<void> {
	const userStore = useUserStore();
	await privateApiRequest.put(`/identity/date-of-birth/save`, {
		dateOfBirth: dayjs(userStore.dob, DATE_FORMAT, true).format(SERVER_DATE_FORMAT)
	});
}

export async function saveUserDetails(): Promise<void> {
	const userStore = useUserStore();
	await privateApiRequest.put(`/identity/user-details/save`, {
		firstName: userStore.firstName,
		lastName: userStore.lastName
	});
}

export async function submitUserPhoneNumber(newPhoneNumber?: string): Promise<number> {
	let phoneNumber: string | undefined | null = newPhoneNumber;
	if (!phoneNumber) {
		const userStore = useUserStore();
		phoneNumber = userStore.phoneNumber;
	}
	const response = await privateApiRequest.post(`/identity/phone-number/submit`, {
		phoneNumber
	});
	return response.data.phoneNumberId;
}

export async function getUserPhoneNumber(isMasked: boolean): Promise<PhoneNumberResponse> {
	const response = await privateApiRequestWithoutErrorAlert.get(`/user/phone-number`, { params: { isMasked } });
	return response.data;
}

export async function saveUserPhoneNumber(newPhoneNumber?: string, withErrorAlert = true): Promise<number> {
	const userStore = useUserStore();
	let request: AxiosInstance;
	if (withErrorAlert) {
		request = privateApiRequest;
	} else {
		request = privateApiRequestWithoutErrorAlert;
	}

	let phoneNumber: string | undefined | null = newPhoneNumber;
	if (!phoneNumber) {
		phoneNumber = userStore.phoneNumber;
	}

	const response = await request.put(`/identity/phone-number/save`, {
		phoneNumber
	});
	return response.data.phoneNumberId;
}

export async function saveTaxpayerIdentificationNumber(
	taxpayerIdentificationNumber: string
): Promise<{ attemptsRemaining: number }> {
	const userStore = useUserStore();
	const data = await privateApiRequest.put(`/identity/legal-entity/${userStore.legalEntityId}/tin/save`, {
		taxpayerIdentificationNumber
	});
	return data.data;
}

export async function submitPersonalIdentificationNumber(
	personalIdentificationNumber: string
): Promise<{ attemptsRemaining: number }> {
	const userStore = useUserStore();
	const data = await privateApiRequest.post(`/seller-identifier/legal-entity/${userStore.legalEntityId}/pin/submit`, {
		personalIdentificationNumber
	});
	return data.data;
}

export async function saveEmailAddress(email: string, password: string): Promise<void> {
	await privateApiRequestWithoutErrorAlert.put(`/identity/email-address/save`, {
		email,
		password
	});
}

export async function savePassword(currentPassword: string, newPassword: string): Promise<void> {
	await privateApiRequestWithoutErrorAlert.put(`/identity/password/save`, {
		currentPassword,
		newPassword
	});
}
