import { privateApiRequest, privateApiRequestWithoutErrorAlert } from '@utils/request';
import { useUserStore } from '@store/user-store';
import type {
	BankAccount,
	BankAccountsListResponse,
	ManualBankAccountRequest,
	ManualBankAccountResponse
} from 'types/api/bank-account';
import type { PlaidBankAccountRequest, PlaidBankAccountResponse } from 'types/third-party/plaid';
import type { AxiosResponse } from 'axios';

export async function submitPlaidBankAccount(payload: PlaidBankAccountRequest): Promise<PlaidBankAccountResponse> {
	const userStore = useUserStore();
	const response = await privateApiRequest.post(
		`/bank-account/legal-entity/${userStore.legalEntityId}/plaid/submit`,
		payload
	);
	return response.data;
}

export async function submitManualBankAccount(payload: ManualBankAccountRequest): Promise<ManualBankAccountResponse> {
	const userStore = useUserStore();
	const response = await privateApiRequest.post(
		`/bank-account/legal-entity/${userStore.legalEntityId}/manual/submit`,
		payload
	);
	return response.data;
}

export async function saveBankAccountToSecondaryTransaction(
	secondaryTransactionId: number,
	bankAccountId: number,
	updatePrimaryAccount: boolean
): Promise<PlaidBankAccountResponse> {
	const userStore = useUserStore();
	const response = await privateApiRequest.put(
		`/bank-account/legal-entity/${userStore.legalEntityId}/secondary-transaction/${secondaryTransactionId}/bank-account/${bankAccountId}/save`,
		{ updatePrimaryAccount }
	);
	return response.data;
}

export async function getBankAccounts(): Promise<Array<BankAccount>> {
	const userStore = useUserStore();
	const response: AxiosResponse<BankAccountsListResponse> = await privateApiRequest.get(
		`/bank-account/legal-entity/${userStore.legalEntityId}/list`
	);
	return response.data.bankAccounts;
}

export async function deleteBankAccount(bankAccountId: number) {
	const userStore = useUserStore();
	const response = await privateApiRequestWithoutErrorAlert.delete(
		`/bank-account/legal-entity/${userStore.legalEntityId}/bank-account/${bankAccountId}/delete`
	);
	return response.data;
}

export async function savePrimaryBankAccount(bankAccountId: number) {
	const userStore = useUserStore();
	const response = await privateApiRequestWithoutErrorAlert.put(
		`/bank-account/legal-entity/${userStore.legalEntityId}/bank-account/${bankAccountId}/primary/save`
	);
	return response.data;
}
