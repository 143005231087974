import { acceptHMRUpdate, defineStore } from 'pinia';
import { getCsrfToken as getCsrfTokenFromApi } from '@api/auth';
import { getScreenSize } from '@shared/utils/client';
import { Platform } from 'quasar';
import { ref } from 'vue';
import { useSignUpStore } from '@store/sign-up-store';
import type { Alert } from 'types/alerts';
import type { RouteLocationNormalized } from 'vue-router';

export const useAppStore = defineStore('app', () => {
	const alerts = ref<Alert[]>([]);
	const csrfToken = ref('');
	const csrfHeaderName = ref('');
	const csrfParameterName = ref('');
	const isMobile = ref(Platform.is.mobile);
	const routeTransition = ref({
		scope: 'fullpage',
		name: 'fade'
	});
	const screenSize = ref(getScreenSize());
	const bankAccountManualLinkNextRoute = ref<null | string>(null);
	const mixpanelIdentified = ref<null | boolean>(null);

	function addAlert(alert: Alert) {
		alerts.value.push(alert);
	}

	function dismissAlert(alertToDismiss: Alert) {
		const indexOfAlert = alerts.value.findIndex(
			(alert) => alert.message === alertToDismiss.message && alert.type === alertToDismiss.type
		);
		if (indexOfAlert !== -1) {
			alerts.value.splice(indexOfAlert, 1);
		}
	}

	async function getCsrfToken() {
		const csrf = await getCsrfTokenFromApi();
		csrfToken.value = csrf.token;
		csrfHeaderName.value = csrf.headerName;
		csrfParameterName.value = csrf.parameterName;
	}

	function updateRouteTransition({ from, to }: { from: RouteLocationNormalized; to: RouteLocationNormalized }) {
		const signUpStore = useSignUpStore();
		const fromStepIndex = signUpStore.signUpSteps.indexOf(from.name?.toString() ?? '');
		const toStepIndex = signUpStore.signUpSteps.indexOf(to.name?.toString() ?? '');

		if (toStepIndex >= 0 && fromStepIndex >= 0) {
			routeTransition.value.scope = 'subpage';
			if (fromStepIndex < toStepIndex) {
				routeTransition.value.name = 'slide-left';
			} else if (fromStepIndex > toStepIndex) {
				routeTransition.value.name = 'slide-right';
			} else {
				routeTransition.value.name = 'fade';
			}
		} else {
			routeTransition.value.scope = 'fullpage';
			routeTransition.value.name = 'fade';
		}
	}

	return {
		alerts,
		csrfToken,
		csrfHeaderName,
		csrfParameterName,
		isMobile,
		routeTransition,
		screenSize,
		bankAccountManualLinkNextRoute,
		mixpanelIdentified,
		addAlert,
		dismissAlert,
		getCsrfToken,
		updateRouteTransition
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAppStore as any, import.meta.hot));
}
