import { privateApiRequest, privateApiRequestWithoutErrorAlert } from '@utils/request';
import { useUserStore } from '@store/user-store';
import type { Address } from 'types/user/user';

export async function submitNewAddress(): Promise<void> {
	const userStore = useUserStore();
	if (userStore.legalEntityId) {
		await privateApiRequest.post(`/address/legal-entity/${userStore.legalEntityId}/submit`, userStore.address);
	} else if (!userStore.legalEntityId) {
		await Promise.reject(new Error('No legal entity id provided'));
	} else if (!userStore.address) {
		await Promise.reject(new Error('No address provided'));
	}
}

export async function getCurrentlyActiveAddress(): Promise<Address> {
	const userStore = useUserStore();

	const response = await privateApiRequestWithoutErrorAlert.get(
		`/address/legal-entity/${userStore.legalEntityId}/active/address`
	);
	return response.data;
}
