import Logger from 'js-logger';
import { useAppStore } from '@store/app-store';
import { useUserStore } from '@store/user-store';
import type * as Mixpanel from '@0no-co/mixpanel-micro';
import type { Company } from 'types/company/company';

let mixpanel: typeof Mixpanel | null = null;

export function initMixpanel(): void {
	import('@0no-co/mixpanel-micro')
		.then((Mixpanel) => {
			mixpanel = Mixpanel;
			mixpanel.init(process.env.VITE_MIXPANEL_TOKEN!);
		})
		.catch(() => Logger.error('Mixpanel failed to load'));
}

export function trackMixpanel(eventName: string, properties?: { [key: string]: any }): void {
	const appStore = useAppStore();
	const userStore = useUserStore();

	if (userStore.userId && !appStore.mixpanelIdentified) {
		mixpanel?.identify(userStore.userId.toString());
		mixpanel?.setPeople({
			userId: userStore.userId,
			legalEntityId: userStore.legalEntityId
		});
		appStore.mixpanelIdentified = true;
	}
	mixpanel?.track(eventName, properties);
}
export function pageViewed(pageName: string, properties?: { [key: string]: any }): void {
	trackMixpanel('Page viewed', {
		...properties,
		pageName
	});
}
export function pageClicked(
	clickedElement: string,
	clickedElementText: string,
	properties?: { [key: string]: any }
): void {
	trackMixpanel('Page clicked', {
		...properties,
		clickedElement,
		clickedElementText
	});
}
export function loginMixpanel(from: string): void {
	trackMixpanel('Log in', { from });
}
export function signUpMixpanel(from: string): void {
	trackMixpanel('Sign up', { from });
}
export function resetMixpanel(): void {
	mixpanel?.init('');
	mixpanel?.register({
		$os: null,
		$browser: null,
		$browser_version: null,
		$device: null,
		$screen_height: 0,
		$screen_width: 0,
		$referrer: '',
		$referring_domain: null,
		$device_id: '',
		token: '',
		$anon_distinct_id: '',
		distinct_id: '',
		mp_lib: '',
		doNotTrack: false,
		time: 0,
		$current_url: '',
		$user_id: '',
		$insert_id: '',
		userId: undefined,
		legalEntityId: undefined
	});
}
export function signOutMixpanel(from: string): void {
	trackMixpanel('Sign out', { from });
	resetMixpanel();
}
export function signUpStepMixpanel(step: string, properties?: { [key: string]: any }): void {
	trackMixpanel(`Sign up step: ${step}`, properties);
}

export function formatCompanyForMixpanel(company: Company) {
	type CompanyKey = keyof Company;
	type CompanyValues = (typeof company)[CompanyKey];
	return Object.keys(company).reduce((acc: { [key: string]: CompanyValues }, key) => {
		acc[`company${key[0].toUpperCase()}${key.slice(1)}`] = company[key as CompanyKey];
		return acc;
	}, {});
}
