import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
	getBankAccounts as getBankAccountsFromApi,
	saveBankAccountToSecondaryTransaction,
	submitPlaidBankAccount as submitPlaidBankAccountFromApi
} from '@api/bank-account';
import {
	getUserEmailIsVerified,
	getUserIdentity,
	getUserIsInCommunityPropertyState,
	getUserMaritalStatusSubmission
} from '@api/graphql/individual/user';
import { getUserPhoneNumber, saveDateOfBirth as saveDateOfBirthFromApi } from '@api/identity';
import { getCurrentlyActiveAddress as getCurrentlyActiveAddressFromApi } from '@api/address';
import { useAppStore } from '@store/app-store';
import { useLogger } from '@utils/composables/useLogger';
import { useSignUpStore } from '@store/sign-up-store';
import type { Address } from 'types/user/user';
import type { AutomaticVerificationDetailsResponse } from 'types/api/automatic-verification';
import type { BankAccount } from 'types/api/bank-account';
import type { CheckoutDetailsResponse } from 'types/api/checkout';
import type { PlaidBankAccountRequest } from 'types/third-party/plaid';
import type { UserNotificationSettings } from 'types/api/user-notification-settings';

export function emptyAddress(): Address {
	return {
		addressLineOne: '',
		addressLineTwo: '',
		city: '',
		state: '',
		zipcode: '',
		countryCode: '',
		isConfirmed: false
	};
}

const { userStoreLogger } = useLogger('user-store');

export const useUserStore = defineStore('user', () => {
	const address = ref<Address>(emptyAddress());
	const addressFromApi = ref<null | Address>(null);
	const authLoading = ref(false);
	const bankAccounts = ref<BankAccount[]>([]);
	const dob = ref<null | string>(null);
	const email = ref<null | string | undefined>(null);
	const firstName = ref<null | string>(null);
	const hasSpouse = ref<null | boolean>(null);
	const hasVerifiedEmail = ref<null | boolean>(null);
	const hasVerifiedPhoneNumber = ref<null | boolean>(null);
	const isAuthenticated = ref<null | boolean>(null);
	const isCompanyAdministrator = ref<null | boolean>(null);
	const isInCommunityPropertyState = ref<null | boolean>(null);
	const isUsernamePasswordAuthenticationStrategy = ref<null | boolean>(null);
	const lastName = ref<null | string>(null);
	const legalEntityId = ref<null | number>(null);
	const notificationSettings = ref<null | UserNotificationSettings>(null);
	const phoneNumber = ref<null | string>(null);
	const primaryBankName = ref('');
	const primaryBankAccountNumberLastFour = ref('');
	const retrievedUserDetails = ref(false);
	const spouseEmail = ref('');
	const spouseFirstName = ref('');
	const spouseLastName = ref('');
	const userId = ref<null | number>(null);

	const addressIsFilledOut = computed(() => {
		return !!(address.value.addressLineOne && address.value.city && address.value.state && address.value.zipcode);
	});
	const needsToFillOutSpousalConsent = computed(() => {
		const spouseInformationIsComplete = spouseFirstName.value && spouseLastName.value && spouseEmail.value;
		return (
			isInCommunityPropertyState.value &&
			(hasSpouse.value === null || (hasSpouse.value === true && !spouseInformationIsComplete))
		);
	});

	async function getBankAccounts() {
		if (legalEntityId.value) {
			bankAccounts.value = await getBankAccountsFromApi();
		}
	}

	async function getCurrentlyActiveAddress() {
		if (legalEntityId.value) {
			try {
				address.value = await getCurrentlyActiveAddressFromApi();
			} catch {
				return userStoreLogger.error('No address found');
			}
		}
	}

	async function getHasVerifiedEmail() {
		if (legalEntityId.value) {
			hasVerifiedEmail.value = await getUserEmailIsVerified();
		}
	}

	async function getIsInCommunityPropertyState() {
		if (legalEntityId.value) {
			isInCommunityPropertyState.value = await getUserIsInCommunityPropertyState();
		}
	}

	async function getMaritalStatusSubmission() {
		if (legalEntityId.value) {
			const maritalStatusSubmission = await getUserMaritalStatusSubmission();
			hasSpouse.value = maritalStatusSubmission.hasSpouse;
			if (maritalStatusSubmission.spouseDetails) {
				spouseFirstName.value = maritalStatusSubmission.spouseDetails.firstName;
				spouseLastName.value = maritalStatusSubmission.spouseDetails.lastName;
				spouseEmail.value = maritalStatusSubmission.spouseDetails.email;
			}
		}
	}

	async function getPhoneNumber(isMasked = false) {
		const phoneNumberResponse = await getUserPhoneNumber(isMasked);
		phoneNumber.value = phoneNumberResponse.phoneNumber ?? null;
		hasVerifiedPhoneNumber.value = phoneNumberResponse.isVerified ?? null;
	}

	async function getUserDetails() {
		if (!retrievedUserDetails.value && !authLoading.value) {
			authLoading.value = true;
			try {
				const appStore = useAppStore();
				const signUpStore = useSignUpStore();
				await appStore.getCsrfToken();
				const userDetails = await getUserIdentity();
				firstName.value = userDetails.firstName;
				lastName.value = userDetails.lastName;
				email.value = userDetails.email;
				userId.value = userDetails.userId;
				legalEntityId.value = userDetails.legalEntityId;
				isUsernamePasswordAuthenticationStrategy.value = userDetails.isUsernamePasswordAuthenticationStrategy;
				isCompanyAdministrator.value = userDetails.isCompanyAdministrator;
				hasSpouse.value = userDetails.hasSpouse;
				spouseEmail.value = userDetails.spouseEmail;
				spouseFirstName.value = userDetails.spouseFirstName;
				spouseLastName.value = userDetails.spouseLastName;
				isAuthenticated.value = true;
				retrievedUserDetails.value = true;
				await signUpStore.getHasCompletedFirstTimeCheckout();
			} catch (error) {
				isAuthenticated.value = false;
				throw error;
			} finally {
				authLoading.value = false;
			}
		}
	}

	async function getUserDetailsWithoutError() {
		try {
			await getUserDetails();
		} catch (e) {}
	}

	async function saveDateOfBirth(date: string | null) {
		dob.value = date;
		if (dob.value) {
			await saveDateOfBirthFromApi();
		}
	}

	async function saveNewBankAccountToSecondaryTransaction(bankAccountId: number, updatePrimaryAccount = true) {
		const signUpStore = useSignUpStore();
		await saveBankAccountToSecondaryTransaction(
			signUpStore.transactionDetails.secondaryTransactionId!,
			bankAccountId,
			updatePrimaryAccount
		);
	}

	function setCheckoutDetails(savedDetails: CheckoutDetailsResponse | AutomaticVerificationDetailsResponse) {
		if ('userDetails' in savedDetails && savedDetails.userDetails) {
			firstName.value = savedDetails.userDetails.firstName ?? null;
			lastName.value = savedDetails.userDetails.lastName ?? null;
		}
		if (savedDetails.phoneNumber) {
			phoneNumber.value = savedDetails.phoneNumber.phoneNumber ?? null;
			hasVerifiedPhoneNumber.value = savedDetails.phoneNumber.isVerified ?? false;
		}
		if (savedDetails.address) {
			addressFromApi.value = savedDetails.address;
			address.value.addressLineOne = savedDetails.address.addressLineOne ?? '';
			address.value.addressLineTwo = savedDetails.address.addressLineTwo ?? '';
			address.value.city = savedDetails.address.city ?? '';
			address.value.state = savedDetails.address.state ?? '';
			address.value.zipcode = savedDetails.address.zipcode ?? '';
			address.value.countryCode = savedDetails.address.countryCode ?? '';
			address.value.isConfirmed = savedDetails.address.isConfirmed ?? false;
		}
	}

	async function submitPlaidBankAccount(
		linkRequest: PlaidBankAccountRequest,
		saveToSecondaryTransaction = true,
		saveToSignUpStore = false
	) {
		const response = await submitPlaidBankAccountFromApi(linkRequest);
		if (saveToSecondaryTransaction) {
			await saveNewBankAccountToSecondaryTransaction(response.bankAccountId, false);
		}
		if (saveToSignUpStore) {
			const signUpStore = useSignUpStore();
			signUpStore.bankAccountId = response.bankAccountId;
		}
	}

	return {
		address,
		addressFromApi,
		authLoading,
		bankAccounts,
		dob,
		email,
		firstName,
		hasSpouse,
		hasVerifiedEmail,
		hasVerifiedPhoneNumber,
		isAuthenticated,
		isCompanyAdministrator,
		isInCommunityPropertyState,
		isUsernamePasswordAuthenticationStrategy,
		lastName,
		legalEntityId,
		needsToFillOutSpousalConsent,
		notificationSettings,
		phoneNumber,
		primaryBankName,
		primaryBankAccountNumberLastFour,
		retrievedUserDetails,
		spouseEmail,
		spouseFirstName,
		spouseLastName,
		userId,
		addressIsFilledOut,
		getBankAccounts,
		getCurrentlyActiveAddress,
		getHasVerifiedEmail,
		getIsInCommunityPropertyState,
		getMaritalStatusSubmission,
		getPhoneNumber,
		getUserDetails,
		getUserDetailsWithoutError,
		saveDateOfBirth,
		saveNewBankAccountToSecondaryTransaction,
		setCheckoutDetails,
		submitPlaidBankAccount
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot));
}
