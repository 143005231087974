import { privateApiRequest } from '@utils/request';
import { useSignUpStore } from '@store/sign-up-store';
import { useUserStore } from '@store/user-store';
import type { IncorrectPriceUpdatePayload, OfferAcceptanceResponse } from 'types/api/offer-presentation';

export async function submitAcceptanceOfOffer(
	secondaryTransactionId: number,
	fractionalShares: number,
	amount: number
): Promise<OfferAcceptanceResponse> {
	const userStore = useUserStore();

	const response = await privateApiRequest.post(
		`/offer-presentation/legal-entity/${userStore.legalEntityId}/secondary-transaction/${secondaryTransactionId}/submit`,
		{
			fractionalShares,
			amount
		}
	);
	return response.data;
}

export async function submitIncorrectPriceUpdate(
	payload: IncorrectPriceUpdatePayload,
	securityIssuanceId: number
): Promise<void> {
	const userStore = useUserStore();

	await privateApiRequest.post(
		`/offer-presentation/legal-entity/${userStore.legalEntityId}/security-issuance/${securityIssuanceId}/incorrect-price/submit`,
		payload
	);
}

export async function saveWaitForVerifiedOffer(): Promise<void> {
	const signUpStore = useSignUpStore();
	const userStore = useUserStore();

	await privateApiRequest.put(
		`/offer-presentation/legal-entity/${userStore.legalEntityId}/secondary-transaction/${signUpStore.transactionDetails.secondaryTransactionId}/wait-for-verified-offer/save`
	);
}
