import { privateApiRequest, privateApiRequestWithoutErrorAlert } from '@utils/request';
import { useUserStore } from '@store/user-store';
import type {
	SecurityIssuance,
	SecurityIssuanceDocument,
	SecurityIssuanceResponse,
	SecurityIssuanceVerificationDetailsResponse
} from 'types/api/security-issuance';
import type { PresignedUrlDetails } from 'types/api/presigned-url';
import type { SecondaryTransaction } from 'types/api/secondary-transaction';

export async function getOrCreateVerifiedOfferForSecurityIssuance(
	securityIssuanceId: number
): Promise<Pick<SecondaryTransaction, 'secondaryTransactionId' | 'pricePerShare' | 'fractionalShares'>> {
	const userStore = useUserStore();

	const response = await privateApiRequestWithoutErrorAlert.put(
		`/security-issuance/legal-entity/${userStore.legalEntityId}/security-issuance/${securityIssuanceId}/verified-offer/save`
	);
	return response.data;
}

export async function getSecurityIssuanceList(verificationStatuses?: string[]): Promise<SecurityIssuanceResponse> {
	const userStore = useUserStore();

	const queryParams = verificationStatuses
		? `${verificationStatuses.map((status) => `verificationStatuses=${status}`).join('&')}`
		: '';

	const response = await privateApiRequest.get(
		`/security-issuance/legal-entity/${userStore.legalEntityId}/list?${queryParams}`
	);
	return response.data;
}

export async function getSecurityIssuance(securityIssuanceId: number): Promise<SecurityIssuance> {
	const userStore = useUserStore();

	const response = await privateApiRequest.get(
		`/security-issuance/legal-entity/${userStore.legalEntityId}/security-issuance/${securityIssuanceId}`
	);
	return response.data;
}

export async function getSecurityIssuanceDocumentPresignedUploadUrl(
	securityIssuanceId: number,
	fileName: string
): Promise<PresignedUrlDetails> {
	const userStore = useUserStore();

	const response = await privateApiRequest.get(
		`/security-issuance/legal-entity/${userStore.legalEntityId}/security-issuance/${securityIssuanceId}/upload-verification`,
		{
			params: {
				fileName
			}
		}
	);
	return response.data;
}

export async function getSecurityIssuanceVerificationDetails(
	securityIssuanceId: number
): Promise<SecurityIssuanceDocument[]> {
	const userStore = useUserStore();

	const response = await privateApiRequest.get<SecurityIssuanceVerificationDetailsResponse>(
		`/security-issuance/legal-entity/${userStore.legalEntityId}/security-issuance/${securityIssuanceId}/verification-details/list`
	);
	return response.data.securityIssuanceDocuments;
}
