export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	BigDecimal: { input: string; output: string };
	BigInteger: { input: any; output: any };
	Boolean: { input: boolean; output: boolean };
	Float: { input: number; output: number };
	ID: { input: string; output: string };
	Int: { input: number; output: number };
	LocalDate: { input: string; output: string };
	LocalDateTime: { input: string; output: string };
	String: { input: string; output: string };
	ZonedDateTime: { input: any; output: any };
};

export type AcknowledgmentInterface = {
	id: Scalars['Int']['output'];
	mostRecentRevision: AcknowledgmentRevisionInterface;
	title: Scalars['String']['output'];
	type: AcknowledgmentTypeEnum;
};

export type AcknowledgmentRevisionInterface = {
	id: Scalars['Int']['output'];
	revisionNumber: Scalars['Int']['output'];
	text: Scalars['String']['output'];
};

export enum AcknowledgmentTypeEnum {
	ForwardSaleAcknowledgment = 'FORWARD_SALE_ACKNOWLEDGMENT'
}

export type AddressInterface = {
	id: Scalars['Int']['output'];
	/** True if the address resides in a community property state (for spousal consent). */
	isInCommunityPropertyState: Scalars['Boolean']['output'];
};

export type Administrator_CompanyControlGroup = CompanyControlGroupInterface & {
	allowance: Administrator_CompanyControlGroupAllowance;
	id: Scalars['Int']['output'];
	memberCount: Scalars['Int']['output'];
	members: Array<Administrator_CompanyControlSeller>;
	name: Scalars['String']['output'];
	portfolioSummary: Administrator_PortfolioSummary;
	__typename?: 'Administrator_CompanyControlGroup';
};

export type Administrator_CompanyControlGroupAllowance = CompanyControlGroupAllowanceInterface & {
	id: Scalars['Int']['output'];
	percentageCap: Scalars['BigDecimal']['output'];
	__typename?: 'Administrator_CompanyControlGroupAllowance';
};

export type Administrator_CompanyControlIssuer = CompanyControlIssuerInterface & {
	id: Scalars['Int']['output'];
	issuer: IssuerLegalEntity;
	__typename?: 'Administrator_CompanyControlIssuer';
};

export type Administrator_CompanyControlSeller = CompanyControlSellerInterface & {
	group: Administrator_CompanyControlGroup;
	id: Scalars['Int']['output'];
	portfolioSummary: Administrator_PortfolioSummary;
	secondaryTransactions: Array<Administrator_SecondaryTransaction>;
	securityIssuanceCount: Scalars['Int']['output'];
	securityIssuances: Array<Administrator_SecurityIssuance>;
	seller: Administrator_SellerLegalEntity;
	__typename?: 'Administrator_CompanyControlSeller';
};

export type Administrator_Email = EmailInterface & {
	email: Scalars['String']['output'];
	__typename?: 'Administrator_Email';
};

export type Administrator_PortfolioSummary = PortfolioSummaryInterface & {
	remainingAllowanceValue: Scalars['BigDecimal']['output'];
	totalAllowanceValue: Scalars['BigDecimal']['output'];
	totalAwardedUnits: Scalars['BigDecimal']['output'];
	totalTransactedValue: Scalars['BigDecimal']['output'];
	totalVestedOutstandingUnits: Scalars['BigDecimal']['output'];
	totalVestedOutstandingValue: Scalars['BigDecimal']['output'];
	__typename?: 'Administrator_PortfolioSummary';
};

export type Administrator_SecondaryTransaction = SecondaryTransactionInterface & {
	amount: Scalars['BigDecimal']['output'];
	companyProceeds: Scalars['BigDecimal']['output'];
	fractionalShares: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	netValuePerShare: Scalars['BigDecimal']['output'];
	offerType: SecondaryTransactionOfferTypeEnum;
	pricePerShare: Scalars['BigDecimal']['output'];
	secondaryTransactionDocuments: Array<SecondaryTransactionDocument>;
	securityIssuance: Administrator_SecurityIssuance;
	sellerProceeds: Scalars['BigDecimal']['output'];
	status: SecondaryTransactionStatusEnum;
	type: SecondaryTransactionTypeEnum;
	__typename?: 'Administrator_SecondaryTransaction';
	acceptedDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
	transactionDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type Administrator_Security = SecurityInterface & {
	currentPricePerShare: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	issuer: IssuerLegalEntity;
	name: Scalars['String']['output'];
	securityClass: SecurityClassEnum;
	securityClassType: SecurityClassTypeEnum;
	securityType: SecurityTypeEnum;
	__typename?: 'Administrator_Security';
};

export type Administrator_SecurityIssuance = SecurityIssuanceInterface & {
	displayName: Scalars['String']['output'];
	fractionalShares: Scalars['BigDecimal']['output'];
	fractionalSharesTransactedToDate: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	reportedDateTime: Scalars['LocalDateTime']['output'];
	secondaryTransactions: Array<Administrator_SecondaryTransaction>;
	security: Administrator_Security;
	seller: Administrator_SellerLegalEntity;
	verificationStatus: SecurityIssuanceVerificationStatusEnum;
	vestedOutstandingShares: Scalars['BigDecimal']['output'];
	vestedValue: Scalars['BigDecimal']['output'];
	__typename?: 'Administrator_SecurityIssuance';
	awardDate?: Maybe<Scalars['LocalDate']['output']>;
	exercisedShares?: Maybe<Scalars['BigDecimal']['output']>;
	fractionalSharesVestedToDate?: Maybe<Scalars['BigDecimal']['output']>;
	identifier?: Maybe<Scalars['String']['output']>;
	strikePrice?: Maybe<Scalars['BigDecimal']['output']>;
};

export type Administrator_SellerLegalEntity = SellerLegalEntityInterface & {
	companyControlSeller: Administrator_CompanyControlSeller;
	id: Scalars['Int']['output'];
	secondaryTransactions: Array<Administrator_SecondaryTransaction>;
	securityIssuanceCount: Scalars['Int']['output'];
	securityIssuances: Array<Administrator_SecurityIssuance>;
	user: Administrator_User;
	__typename?: 'Administrator_SellerLegalEntity';
};

export type Administrator_User = UserInterface & {
	email: Administrator_Email;
	id: Scalars['Int']['output'];
	isUsernamePasswordAuthenticationStrategy: Scalars['Boolean']['output'];
	legalEntity: Administrator_SellerLegalEntity;
	__typename?: 'Administrator_User';
	dateOfBirth?: Maybe<Scalars['LocalDate']['output']>;
	firstName?: Maybe<Scalars['String']['output']>;
	lastName?: Maybe<Scalars['String']['output']>;
};

export type BankAccountInterface = {
	accountLastFour: Scalars['String']['output'];
	bankName: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	isPrimary: Scalars['Boolean']['output'];
	type: BankAccountTypeEnum;
};

export enum BankAccountTypeEnum {
	Checking = 'CHECKING',
	Savings = 'SAVINGS'
}

export type CompanyControlGroupAllowanceInterface = {
	id: Scalars['Int']['output'];
	/**
	 * The percentage of the group member's vested value they are allowed to sell.
	 * Values are between 0.0 and 1.0 (i.e. 50% will be represented as .50).
	 */
	percentageCap: Scalars['BigDecimal']['output'];
};

export type CompanyControlGroupInterface = {
	allowance: CompanyControlGroupAllowanceInterface;
	id: Scalars['Int']['output'];
	/** The number of members in this group. This should be preferred over counting the number of items in the `members` field. */
	memberCount: Scalars['Int']['output'];
	/** A list of all group members. */
	members: Array<CompanyControlSellerInterface>;
	/** The human readable name of this group. */
	name: Scalars['String']['output'];
};

export type CompanyControlIssuerInterface = {
	id: Scalars['Int']['output'];
	issuer: IssuerLegalEntityInterface;
};

export type CompanyControlSellerInterface = {
	id: Scalars['Int']['output'];
	portfolioSummary: PortfolioSummaryInterface;
	/** All secondary transactions associated with this seller, filtered by issuer. */
	secondaryTransactions: Array<SecondaryTransactionInterface>;
	/** Count of all security issuances associated with this company control seller, filtered by issuer. */
	securityIssuanceCount: Scalars['Int']['output'];
	/** All security issuances associated with this company control seller, filtered by issuer. */
	securityIssuances: Array<SecurityIssuanceInterface>;
	seller: SellerLegalEntityInterface;
};

export type DocumentInterface = {
	id: Scalars['Int']['output'];
	mostRecentRevision: DocumentRevisionInterface;
	title: Scalars['String']['output'];
	type: DocumentTypeEnum;
};

export type DocumentRevisionInterface = {
	id: Scalars['Int']['output'];
	presignedUrl: DocumentRevisionPresignedUrl;
	revisionNumber: Scalars['Int']['output'];
};

export type DocumentRevisionPresignedUrl = PresignedUrlInterface & {
	expirationDateTime: Scalars['ZonedDateTime']['output'];
	key: Scalars['String']['output'];
	url: Scalars['String']['output'];
	__typename?: 'DocumentRevisionPresignedUrl';
};

export enum DocumentTypeEnum {
	OfferPresentation = 'OFFER_PRESENTATION',
	Other = 'OTHER'
}

export type EmailInterface = {
	email: Scalars['String']['output'];
};

export enum EquityImportMappingTypeEnum {
	Pin = 'PIN',
	Ssn = 'SSN'
}

export type Individual_Acknowledgment = AcknowledgmentInterface & {
	id: Scalars['Int']['output'];
	mostRecentRevision: Individual_AcknowledgmentRevision;
	title: Scalars['String']['output'];
	type: AcknowledgmentTypeEnum;
	__typename?: 'Individual_Acknowledgment';
};

export type Individual_AcknowledgmentRevision = AcknowledgmentRevisionInterface & {
	id: Scalars['Int']['output'];
	revisionNumber: Scalars['Int']['output'];
	text: Scalars['String']['output'];
	__typename?: 'Individual_AcknowledgmentRevision';
};

export type Individual_Address = AddressInterface & {
	id: Scalars['Int']['output'];
	isInCommunityPropertyState: Scalars['Boolean']['output'];
	__typename?: 'Individual_Address';
};

export type Individual_BankAccount = BankAccountInterface & {
	accountLastFour: Scalars['String']['output'];
	bankName: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	isPrimary: Scalars['Boolean']['output'];
	type: BankAccountTypeEnum;
	__typename?: 'Individual_BankAccount';
};

export type Individual_CompanyControlIssuer = CompanyControlIssuerInterface & {
	id: Scalars['Int']['output'];
	issuer: IssuerLegalEntity;
	__typename?: 'Individual_CompanyControlIssuer';
};

export type Individual_CompanyControlSeller = CompanyControlSellerInterface & {
	companyControlIssuer: Individual_CompanyControlIssuer;
	id: Scalars['Int']['output'];
	portfolioSummary: Individual_PortfolioSummary;
	secondaryTransactions: Array<Individual_SecondaryTransaction>;
	securityIssuanceCount: Scalars['Int']['output'];
	securityIssuances: Array<Individual_SecurityIssuance>;
	seller: Individual_SellerLegalEntity;
	__typename?: 'Individual_CompanyControlSeller';
};

export type Individual_Document = DocumentInterface & {
	id: Scalars['Int']['output'];
	mostRecentRevision: Individual_DocumentRevision;
	title: Scalars['String']['output'];
	type: DocumentTypeEnum;
	__typename?: 'Individual_Document';
};

export type Individual_DocumentRevision = DocumentRevisionInterface & {
	id: Scalars['Int']['output'];
	presignedUrl: DocumentRevisionPresignedUrl;
	revisionNumber: Scalars['Int']['output'];
	__typename?: 'Individual_DocumentRevision';
};

export type Individual_Email = EmailInterface & {
	email: Scalars['String']['output'];
	__typename?: 'Individual_Email';
	isVerified?: Maybe<Scalars['Boolean']['output']>;
};

export type Individual_MaritalStatusSubmission = MaritalStatusSubmissionInterface & {
	id: Scalars['Int']['output'];
	status: MaritalStatusEnum;
	__typename?: 'Individual_MaritalStatusSubmission';
	spouseDetails?: Maybe<Individual_SpouseDetails>;
};

export type Individual_PortfolioSummary = PortfolioSummaryInterface & {
	remainingAllowanceValue: Scalars['BigDecimal']['output'];
	totalAllowanceValue: Scalars['BigDecimal']['output'];
	totalAwardedUnits: Scalars['BigDecimal']['output'];
	totalTransactedValue: Scalars['BigDecimal']['output'];
	totalVestedOutstandingUnits: Scalars['BigDecimal']['output'];
	totalVestedOutstandingValue: Scalars['BigDecimal']['output'];
	__typename?: 'Individual_PortfolioSummary';
};

export type Individual_SecondaryTransaction = SecondaryTransactionInterface & {
	amount: Scalars['BigDecimal']['output'];
	companyProceeds: Scalars['BigDecimal']['output'];
	fractionalShares: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	netValuePerShare: Scalars['BigDecimal']['output'];
	offerType: SecondaryTransactionOfferTypeEnum;
	pricePerShare: Scalars['BigDecimal']['output'];
	secondaryTransactionDocuments: Array<SecondaryTransactionDocument>;
	securityIssuance: Individual_SecurityIssuance;
	sellerProceeds: Scalars['BigDecimal']['output'];
	status: SecondaryTransactionStatusEnum;
	type: SecondaryTransactionTypeEnum;
	__typename?: 'Individual_SecondaryTransaction';
	acceptedDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
	bankAccount?: Maybe<Individual_BankAccount>;
	transactionDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type Individual_SecondaryTransactionCheckout = SecondaryTransactionCheckoutInterface & {
	acknowledgments: Array<Individual_Acknowledgment>;
	documents: Array<Individual_Document>;
	__typename?: 'Individual_SecondaryTransactionCheckout';
};

export type Individual_Security = SecurityInterface & {
	id: Scalars['Int']['output'];
	issuer: IssuerLegalEntity;
	name: Scalars['String']['output'];
	securityClass: SecurityClassEnum;
	securityClassType: SecurityClassTypeEnum;
	securityType: SecurityTypeEnum;
	__typename?: 'Individual_Security';
	currentPricePerShare?: Maybe<Scalars['BigDecimal']['output']>;
};

export type Individual_SecurityIssuance = SecurityIssuanceInterface & {
	displayName: Scalars['String']['output'];
	fractionalShares: Scalars['BigDecimal']['output'];
	fractionalSharesTransactedToDate: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	reportedDateTime: Scalars['LocalDateTime']['output'];
	secondaryTransactions: Array<Individual_SecondaryTransaction>;
	verificationStatus: SecurityIssuanceVerificationStatusEnum;
	vestedOutstandingShares: Scalars['BigDecimal']['output'];
	__typename?: 'Individual_SecurityIssuance';
	awardDate?: Maybe<Scalars['LocalDate']['output']>;
	exercisedShares?: Maybe<Scalars['BigDecimal']['output']>;
	fractionalSharesVestedToDate?: Maybe<Scalars['BigDecimal']['output']>;
	identifier?: Maybe<Scalars['String']['output']>;
	security?: Maybe<Individual_Security>;
	seller?: Maybe<Individual_SellerLegalEntity>;
	strikePrice?: Maybe<Scalars['BigDecimal']['output']>;
	vestedValue?: Maybe<Scalars['BigDecimal']['output']>;
};

export type Individual_SellerLegalEntity = SellerLegalEntityInterface & {
	id: Scalars['Int']['output'];
	secondaryTransactions: Array<Individual_SecondaryTransaction>;
	securityIssuanceCount: Scalars['Int']['output'];
	securityIssuances: Array<Individual_SecurityIssuance>;
	user: Individual_User;
	__typename?: 'Individual_SellerLegalEntity';
	activeAddress?: Maybe<Individual_Address>;
	companyControlSeller?: Maybe<Individual_CompanyControlSeller>;
	maritalStatusSubmission?: Maybe<Individual_MaritalStatusSubmission>;
};

export type Individual_SpouseDetails = SpouseDetailsInterface & {
	email: Scalars['String']['output'];
	firstName: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	lastName: Scalars['String']['output'];
	__typename?: 'Individual_SpouseDetails';
};

export type Individual_User = UserInterface & {
	email: Individual_Email;
	id: Scalars['Int']['output'];
	isCompanyAdministrator: Scalars['Boolean']['output'];
	isUsernamePasswordAuthenticationStrategy: Scalars['Boolean']['output'];
	legalEntity: Individual_SellerLegalEntity;
	__typename?: 'Individual_User';
	dateOfBirth?: Maybe<Scalars['LocalDate']['output']>;
	firstName?: Maybe<Scalars['String']['output']>;
	lastName?: Maybe<Scalars['String']['output']>;
};

export type IssuerLegalEntity = IssuerLegalEntityInterface & {
	crunchbaseId: Scalars['String']['output'];
	crunchbaseImageId: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	__typename?: 'IssuerLegalEntity';
	details?: Maybe<IssuerLegalEntityDetails>;
};

export type IssuerLegalEntityDetails = IssuerLegalEntityDetailsInterface & {
	equityImportMappingType: EquityImportMappingTypeEnum;
	id: Scalars['Int']['output'];
	isCapTableOnboarded: Scalars['Boolean']['output'];
	__typename?: 'IssuerLegalEntityDetails';
	capTableRefreshDate?: Maybe<Scalars['LocalDate']['output']>;
	valuationInUsd?: Maybe<Scalars['BigInteger']['output']>;
};

export type IssuerLegalEntityDetailsInterface = {
	/** Indicator for the type of method in which pre loaded security issuances are mapped to new users for this issuer. */
	equityImportMappingType: EquityImportMappingTypeEnum;
	id: Scalars['Int']['output'];
	/** Determines if the issuer's equity holders should be onboarded through the pre loaded signup flow (AVF). */
	isCapTableOnboarded: Scalars['Boolean']['output'];
	/**
	 * The date from which we last received updates from the issuer's cap table.
	 * All of our information about security issuances should be current as of this date.
	 */
	capTableRefreshDate?: Maybe<Scalars['LocalDate']['output']>;
	/** The most up to date valuation we have for the issuer. */
	valuationInUsd?: Maybe<Scalars['BigInteger']['output']>;
};

export type IssuerLegalEntityInterface = {
	crunchbaseId: Scalars['String']['output'];
	crunchbaseImageId: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	details?: Maybe<IssuerLegalEntityDetailsInterface>;
};

export enum MaritalStatusEnum {
	WithoutSpouse = 'WITHOUT_SPOUSE',
	WithSpouse = 'WITH_SPOUSE'
}

export type MaritalStatusSubmissionInterface = {
	id: Scalars['Int']['output'];
	/** Indicator of the self-reported field with spouse or without spouse. */
	status: MaritalStatusEnum;
	/** If the status is with spouse, personal details of the spouse. */
	spouseDetails?: Maybe<SpouseDetailsInterface>;
};

export type PortfolioSummaryInterface = {
	/** Total allowance value minus the current net value of any transacted shares at the current net value per share. */
	remainingAllowanceValue: Scalars['BigDecimal']['output'];
	/** The total dollar amount the user would be allowed to sell. */
	totalAllowanceValue: Scalars['BigDecimal']['output'];
	/** The total units that have been awarded, regardless of vesting or sales. */
	totalAwardedUnits: Scalars['BigDecimal']['output'];
	/** The total dollar value  to the seller of all shares that have been sold from this issuance at the current net value per share (regardless of the net value per share at the time of the transaction). */
	totalTransactedValue: Scalars['BigDecimal']['output'];
	/** Equal to the number of units that the seller has available to sell to us, regardless of their allowance or of any already transacted units. */
	totalVestedOutstandingUnits: Scalars['BigDecimal']['output'];
	/** The total dollar value to the seller of the totalVestedOutstandingUnits at the current net value per share. */
	totalVestedOutstandingValue: Scalars['BigDecimal']['output'];
};

export type PresignedUrlInterface = {
	expirationDateTime: Scalars['ZonedDateTime']['output'];
	key: Scalars['String']['output'];
	url: Scalars['String']['output'];
};

export type Query = {
	companyControlGroup: Administrator_CompanyControlGroup;
	companyControlGroups: Array<Administrator_CompanyControlGroup>;
	companyControlIssuer: Administrator_CompanyControlIssuer;
	companyControlSecondaryTransaction: Administrator_SecondaryTransaction;
	companyControlSecondaryTransactionsByStatus: Array<Administrator_SecondaryTransaction>;
	companyControlSecurityIssuance: Administrator_SecurityIssuance;
	companyControlSeller: Administrator_CompanyControlSeller;
	companyControlSellers: Array<Administrator_CompanyControlSeller>;
	issuer: IssuerLegalEntity;
	secondaryTransactionCheckout: Individual_SecondaryTransactionCheckout;
	secondaryTransactionsByStatus: Array<Individual_SecondaryTransaction>;
	user: Individual_User;
	__typename?: 'Query';
};

export type QueryCompanyControlGroupArgs = {
	companyControlGroupId: Scalars['Int']['input'];
};

export type QueryCompanyControlSecondaryTransactionArgs = {
	secondaryTransactionId: Scalars['Int']['input'];
};

export type QueryCompanyControlSecondaryTransactionsByStatusArgs = {
	statuses: Array<SecondaryTransactionStatusEnum>;
};

export type QueryCompanyControlSecurityIssuanceArgs = {
	securityIssuanceId: Scalars['Int']['input'];
};

export type QueryCompanyControlSellerArgs = {
	companyControlSellerId: Scalars['Int']['input'];
};

export type QueryIssuerArgs = {
	issuerId: Scalars['Int']['input'];
};

export type QuerySecondaryTransactionCheckoutArgs = {
	legalEntityId: Scalars['Int']['input'];
	secondaryTransactionId: Scalars['Int']['input'];
};

export type QuerySecondaryTransactionsByStatusArgs = {
	statuses: Array<InputMaybe<SecondaryTransactionStatusEnum>>;
};

export type SecondaryTransactionCheckoutInterface = {
	acknowledgments: Array<AcknowledgmentInterface>;
	documents: Array<DocumentInterface>;
};

export type SecondaryTransactionDocument = SecondaryTransactionDocumentInterface & {
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	__typename?: 'SecondaryTransactionDocument';
};

export type SecondaryTransactionDocumentInterface = {
	id: Scalars['Int']['output'];
	name: Scalars['String']['output'];
};

export type SecondaryTransactionInterface = {
	/** The total amount of this transaction (typically price per share times number of shares). */
	amount: Scalars['BigDecimal']['output'];
	/** The dollar value of the portion of the total amount that will go to the issuer. */
	companyProceeds: Scalars['BigDecimal']['output'];
	/** The total number of shares being sold in this secondary transaction. */
	fractionalShares: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	/** The net value that the seller will receive, per share, for this transaction. */
	netValuePerShare: Scalars['BigDecimal']['output'];
	/** The state of the security issuance associated with this secondary transaction at the time the offer was made. */
	offerType: SecondaryTransactionOfferTypeEnum;
	/** The price per share that was used for this secondary transaction. */
	pricePerShare: Scalars['BigDecimal']['output'];
	/** Any documents associated with this secondary transaction. */
	secondaryTransactionDocuments: Array<SecondaryTransactionDocumentInterface>;
	/** The security issuance associated with this secondary transaction. */
	securityIssuance: SecurityIssuanceInterface;
	/** The dollar value of the portion of the total amount that will go to the seller. */
	sellerProceeds: Scalars['BigDecimal']['output'];
	status: SecondaryTransactionStatusEnum;
	type: SecondaryTransactionTypeEnum;
	/** The date the user confirmed the offer and submitted secondary transaction for approval. */
	acceptedDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
	/** The date the ACH transaction for this secondary transaction was initiated. */
	transactionDateTime?: Maybe<Scalars['LocalDateTime']['output']>;
};

export enum SecondaryTransactionOfferTypeEnum {
	Initial = 'INITIAL',
	Verified = 'VERIFIED'
}

export enum SecondaryTransactionStatusEnum {
	Canceled = 'CANCELED',
	Complete = 'COMPLETE',
	NeedsApproval = 'NEEDS_APPROVAL',
	Offer = 'OFFER',
	Pending = 'PENDING'
}

export enum SecondaryTransactionTypeEnum {
	ForwardPurchaseAndSaleAgreement = 'FORWARD_PURCHASE_AND_SALE_AGREEMENT',
	Sale = 'SALE'
}

export enum SecurityClassEnum {
	A = 'A',
	B = 'B',
	B1 = 'B1',
	C = 'C',
	D = 'D',
	E = 'E',
	F = 'F',
	G = 'G',
	H = 'H',
	I = 'I',
	J = 'J',
	K = 'K',
	L = 'L',
	M = 'M',
	N = 'N',
	O = 'O',
	P = 'P',
	Q = 'Q',
	R = 'R',
	S = 'S',
	T = 'T',
	U = 'U',
	V = 'V',
	W = 'W',
	X = 'X',
	Y = 'Y',
	Z = 'Z'
}

export enum SecurityClassTypeEnum {
	Common = 'COMMON',
	Preferred = 'PREFERRED'
}

export type SecurityInterface = {
	id: Scalars['Int']['output'];
	/** The company that issues this security. */
	issuer: IssuerLegalEntity;
	/** A human readable name for this security. */
	name: Scalars['String']['output'];
	/** The class (like A). */
	securityClass: SecurityClassEnum;
	/** As in common or preferred. */
	securityClassType: SecurityClassTypeEnum;
	/** The type of security, as in options, shares, etc.. */
	securityType: SecurityTypeEnum;
	/** The current price that users could sell shares of security issuances for this security at. */
	currentPricePerShare?: Maybe<Scalars['BigDecimal']['output']>;
};

export type SecurityIssuanceInterface = {
	/** A human readable name for this security issuance. */
	displayName: Scalars['String']['output'];
	/** The total number of shares awarded in this security issuance. */
	fractionalShares: Scalars['BigDecimal']['output'];
	/** The total number of shares transacted (sold) in this security issuance. */
	fractionalSharesTransactedToDate: Scalars['BigDecimal']['output'];
	id: Scalars['Int']['output'];
	/** The date that the security issuance was ingested in the system. */
	reportedDateTime: Scalars['LocalDateTime']['output'];
	/** All secondary transactions associated with this security issuance. */
	secondaryTransactions: Array<SecondaryTransactionInterface>;
	/** Whether we have verified the security issuance yet. */
	verificationStatus: SecurityIssuanceVerificationStatusEnum;
	/** The number of shares that are able to be sold from this security issuance (regardless of allowance or already transacted shares). */
	vestedOutstandingShares: Scalars['BigDecimal']['output'];
	/** The date this security issuance was awarded to the seller. */
	awardDate?: Maybe<Scalars['LocalDate']['output']>;
	/** Any shares from this issuance that have been exercised (making them ineligible to sell from this issuance). */
	exercisedShares?: Maybe<Scalars['BigDecimal']['output']>;
	/** The number of shares, of the total shares, that have vested in this security issuance. */
	fractionalSharesVestedToDate?: Maybe<Scalars['BigDecimal']['output']>;
	/** A unique identifier for this security issuance that will typically be a reference to an identifier from the issuer's cap table management software. */
	identifier?: Maybe<Scalars['String']['output']>;
	/** The security with which this secondary transaction is associated. */
	security?: Maybe<SecurityInterface>;
	/** The seller of this security issuance. */
	seller?: Maybe<SellerLegalEntityInterface>;
	/** If applicable, the strike price for this security issuance. */
	strikePrice?: Maybe<Scalars['BigDecimal']['output']>;
	/** The current value of all vested shares for this issuance. */
	vestedValue?: Maybe<Scalars['BigDecimal']['output']>;
};

export enum SecurityIssuanceVerificationStatusEnum {
	AwaitingVerification = 'AWAITING_VERIFICATION',
	Verified = 'VERIFIED'
}

export enum SecurityTypeEnum {
	Options = 'OPTIONS',
	Rsu = 'RSU',
	Safe = 'SAFE',
	Shares = 'SHARES'
}

export type SellerLegalEntityInterface = {
	id: Scalars['Int']['output'];
	/** All secondary transactions associated with this seller, regardless of the issuer they are for. */
	secondaryTransactions: Array<SecondaryTransactionInterface>;
	/** Count of security issuances associated with this seller, regardless of the issuer they are for. */
	securityIssuanceCount: Scalars['Int']['output'];
	/** All security issuances associated with this seller, regardless of the issuer they are for. */
	securityIssuances: Array<SecurityIssuanceInterface>;
	user: UserInterface;
};

export type SpouseDetailsInterface = {
	email: Scalars['String']['output'];
	firstName: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	lastName: Scalars['String']['output'];
};

export type UserInterface = {
	email: EmailInterface;
	id: Scalars['Int']['output'];
	/** The seller associated with this user. */
	legalEntity: SellerLegalEntityInterface;
	firstName?: Maybe<Scalars['String']['output']>;
	lastName?: Maybe<Scalars['String']['output']>;
};
