import { privateApiRequest, publicApiRequest } from '@utils/request';
import { useSignUpStore } from '@store/sign-up-store';
import type { IssuerResponse, IssuerResponseWithOnboardingStatus } from 'types/api/legal-entity';

export async function submitIssuer(): Promise<IssuerResponse> {
	const signUpStore = useSignUpStore();

	const response = await privateApiRequest.post('/legal-entity/issuer/submit', {
		name: signUpStore.company.label,
		crunchbaseId: signUpStore.company.crunchbaseId,
		crunchbaseImageId: signUpStore.company.crunchbaseImageId
	});
	return response.data;
}

export async function getIssuer(crunchbaseId: string): Promise<IssuerResponseWithOnboardingStatus> {
	const configOptions: { params: { crunchbaseId: string } } = {
		params: {
			crunchbaseId
		}
	};

	const response = await publicApiRequest.get('/legal-entity/issuer/details', configOptions);
	return response.data;
}

export async function getIsInActiveBuybox(): Promise<boolean> {
	const signUpStore = useSignUpStore();

	const response = await publicApiRequest.get(
		`/legal-entity/${signUpStore.company.legalEntityId}/issuer/is-in-active-buybox`
	);
	return response.data;
}

export async function getIsInActiveBuyboxByCrunchbaseId(): Promise<boolean> {
	const signUpStore = useSignUpStore();

	const response = await publicApiRequest.get(
		`/legal-entity/issuer/is-in-active-buybox?crunchbaseId=${signUpStore.company.crunchbaseId}`
	);
	return response.data;
}
