/**
 * An ordered array of the steps in the sign-up flow and the automatic verification flow.
 *
 * If a step is added or removed in either flow, add to or update these arrays to ensure that the "back" behavior works
 * correctly and that we advance to the next step in the flow correctly.
 */

export const SIGN_UP_STEPS_ORDER = [
	'sign-up-company',
	'sign-up-shares',
	'sign-up-offer',
	'sign-up-address',
	'sign-up-basic-info',
	'sign-up-spousal-consent',
	'sign-up-phone-number',
	'sign-up-ssn',
	'sign-up-almost-there',
	'sign-up-review',
	'sign-up-agreements',
	'sign-up-success'
];

export const SIGN_UP_AUTOMATIC_VERIFICATION_SSN_IDENTITY_STEPS_ORDER = [
	'sign-up-company',
	'sign-up-identity',
	'sign-up-equity-details',
	'sign-up-allowance',
	'sign-up-issuance-selection',
	'sign-up-sale-amount',
	'sign-up-address',
	'sign-up-basic-info',
	'sign-up-spousal-consent',
	'sign-up-phone-number',
	'sign-up-almost-there',
	'sign-up-review',
	'sign-up-agreements',
	'sign-up-success'
];

export const SIGN_UP_AUTOMATIC_VERIFICATION_PIN_IDENTITY_STEPS_ORDER = [
	'sign-up-company',
	'sign-up-identity',
	'sign-up-equity-details',
	'sign-up-allowance',
	'sign-up-issuance-selection',
	'sign-up-sale-amount',
	'sign-up-address',
	'sign-up-basic-info',
	'sign-up-spousal-consent',
	'sign-up-phone-number',
	'sign-up-ssn',
	'sign-up-almost-there',
	'sign-up-review',
	'sign-up-agreements',
	'sign-up-success'
];
