import gql from 'graphql-tag';
import { MaritalStatusEnum } from 'types/graphql/graphql';
import { useQuery } from 'villus';
import type {
	UserEmailIsVerifiedQuery,
	UserIdentityQuery,
	UserIsInCommunityPropertyStateQuery,
	UserMaritalStatusSubmissionQuery,
	UserSignUpFlowDeterminationDetailsQuery
} from '@api/graphql/individual/types/user';

export const UserCompanyControlSellerDashboardDetails = gql`
	query UserCompanyControlSellerDashboardDetails {
		user {
			legalEntity {
				companyControlSeller {
					portfolioSummary {
						remainingAllowanceValue
						totalAllowanceValue
						totalAwardedUnits
						totalTransactedValue
						totalVestedOutstandingUnits
						totalVestedOutstandingValue
					}
					secondaryTransactions {
						id
						acceptedDateTime
						fractionalShares
						netValuePerShare
						sellerProceeds
						status
					}
					securityIssuances {
						id
						awardDate
						fractionalShares
						fractionalSharesTransactedToDate
						fractionalSharesVestedToDate
						security {
							currentPricePerShare
							issuer {
								id
								crunchbaseId
								crunchbaseImageId
								name
							}
							name
							securityType
						}
						strikePrice
						vestedOutstandingShares
					}
				}
			}
		}
	}
`;

export const UserCompanyControlSellerWithSecurityIssuanceCountAndIssuerId = gql`
	query UserCompanyControlSellerWithSecurityIssuanceCountAndIssuerId {
		user {
			legalEntity {
				companyControlSeller {
					companyControlIssuer {
						issuer {
							id
						}
					}
					securityIssuanceCount
				}
			}
		}
	}
`;

export const UserCompanyControlSellerWithSecurityIssuances = gql`
	query UserCompanyControlSellerWithSecurityIssuances {
		user {
			legalEntity {
				companyControlSeller {
					portfolioSummary {
						remainingAllowanceValue
						totalAwardedUnits
						totalVestedOutstandingUnits
						totalVestedOutstandingValue
					}
					securityIssuances {
						id
						awardDate
						displayName
						fractionalShares
						fractionalSharesTransactedToDate
						fractionalSharesVestedToDate
						identifier
						security {
							currentPricePerShare
							issuer {
								id
							}
						}
						strikePrice
						vestedOutstandingShares
						vestedValue
					}
				}
			}
		}
	}
`;

const UserEmailIsVerified = gql`
	query UserEmailIsVerified {
		user {
			email {
				isVerified
			}
		}
	}
`;

const UserIdentity = gql`
	query UserIdentity {
		user {
			id
			email {
				email
			}
			firstName
			isCompanyAdministrator
			isUsernamePasswordAuthenticationStrategy
			lastName
			legalEntity {
				id
				maritalStatusSubmission {
					spouseDetails {
						email
						firstName
						lastName
					}
					status
				}
			}
		}
	}
`;

const UserIsInCommunityPropertyState = gql`
	query UserIsInCommunityPropertyState {
		user {
			legalEntity {
				activeAddress {
					isInCommunityPropertyState
				}
			}
		}
	}
`;

export const UserSecondaryTransactions = gql`
	query UserSecondaryTransactions {
		user {
			legalEntity {
				companyControlSeller {
					secondaryTransactions {
						id
						acceptedDateTime
						bankAccount {
							id
							accountLastFour
							bankName
							type
						}
						fractionalShares
						netValuePerShare
						securityIssuance {
							awardDate
							reportedDateTime
							security {
								issuer {
									crunchbaseImageId
									name
								}
								securityClass
								securityType
							}
						}
						sellerProceeds
						status
					}
				}
			}
		}
	}
`;

const UserMaritalStatusSubmission = gql`
	query UserMaritalStatusSubmission {
		user {
			legalEntity {
				maritalStatusSubmission {
					spouseDetails {
						email
						firstName
						lastName
					}
					status
				}
			}
		}
	}
`;

const UserSignUpFlowDeterminationDetails = gql`
	query UserSignUpFlowDeterminationDetails {
		secondaryTransactionsByStatus(statuses: [CANCELED, COMPLETE, NEEDS_APPROVAL, PENDING]) {
			id
		}
		user {
			legalEntity {
				companyControlSeller {
					id
				}
				securityIssuanceCount
			}
		}
	}
`;

export async function getUserIsInCommunityPropertyState() {
	const { data } = await useQuery<UserIsInCommunityPropertyStateQuery>({
		query: UserIsInCommunityPropertyState,
		fetchOnMount: false,
		cachePolicy: 'network-only'
	}).execute();
	return data?.user.legalEntity.activeAddress?.isInCommunityPropertyState ?? null;
}

export async function getUserEmailIsVerified() {
	const { data } = await useQuery<UserEmailIsVerifiedQuery>({
		query: UserEmailIsVerified,
		fetchOnMount: false
	}).execute();
	return data?.user.email.isVerified ?? null;
}

export async function getUserIdentity() {
	const { data, error } = await useQuery<UserIdentityQuery>({
		query: UserIdentity,
		fetchOnMount: false
	}).execute();
	if (error) {
		throw error;
	}

	const maritalStatus = data?.user?.legalEntity?.maritalStatusSubmission?.status;
	return {
		email: data?.user?.email?.email ?? null,
		firstName: data?.user?.firstName ?? null,
		hasSpouse: maritalStatus ? maritalStatus === MaritalStatusEnum.WithSpouse : null,
		isCompanyAdministrator: data?.user?.isCompanyAdministrator ?? null,
		isUsernamePasswordAuthenticationStrategy: data?.user?.isUsernamePasswordAuthenticationStrategy ?? null,
		lastName: data?.user?.lastName ?? null,
		legalEntityId: data?.user?.legalEntity?.id ?? null,
		spouseEmail: data?.user?.legalEntity?.maritalStatusSubmission?.spouseDetails?.email ?? '',
		spouseFirstName: data?.user?.legalEntity?.maritalStatusSubmission?.spouseDetails?.firstName ?? '',
		spouseLastName: data?.user?.legalEntity?.maritalStatusSubmission?.spouseDetails?.lastName ?? '',
		userId: data?.user?.id ?? null
	};
}

export async function getUserMaritalStatusSubmission() {
	const { data } = await useQuery<UserMaritalStatusSubmissionQuery>({
		query: UserMaritalStatusSubmission,
		fetchOnMount: false
	}).execute();
	return {
		hasSpouse: data?.user.legalEntity.maritalStatusSubmission?.status === MaritalStatusEnum.WithSpouse,
		spouseDetails: data?.user.legalEntity.maritalStatusSubmission?.spouseDetails
	};
}

export async function getUserSignUpFlowDeterminationDetails() {
	const { data } = await useQuery<UserSignUpFlowDeterminationDetailsQuery>({
		query: UserSignUpFlowDeterminationDetails,
		fetchOnMount: false
	}).execute();
	return {
		hasCompanyControlSeller: data?.user.legalEntity.companyControlSeller !== null,
		hasPostCheckoutSecondaryTransactions: Number(data?.secondaryTransactionsByStatus.length) > 0,
		hasSecurityIssuances: Number(data?.user.legalEntity.securityIssuanceCount) > 0
	};
}
