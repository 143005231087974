import type { App } from '@vue/runtime-core';
import type { Router } from 'vue-router';

export async function initSentry(app: App<Element>, router: Router) {
	const { BrowserTracing, init: sentryInit, setTag, vueRouterInstrumentation } = await import('@sentry/vue');
	const errorStringToFilter = ['Timeout'];
	const scriptLoadingErrorsToFilterOut = ['acsbapp.com', 'googletagmanager.com', 'sentry.io', 'mixpanel.com'];
	const minimumDurationToSend = 1000;
	const { SessionTiming } = await import('@sentry/integrations');

	const integrations = [
		new SessionTiming(),
		new BrowserTracing({
			routingInstrumentation: vueRouterInstrumentation(router)
		})
	];

	sentryInit({
		app,
		dsn: process.env.VITE_SENTRY_DSN,
		environment: process.env.VITE_SENTRY_ENVIRONMENT,
		integrations,
		attachProps: false,
		trackComponents: true,
		logErrors: true,
		tracesSampleRate: 0.025,
		replaysOnErrorSampleRate: 1,
		beforeSend(event, hint) {
			const originalException = hint?.originalException;

			if (
				hint &&
				originalException &&
				typeof originalException === 'string' &&
				errorStringToFilter.includes(originalException)
			) {
				return null;
			}

			if (
				originalException &&
				((originalException as ErrorEvent)?.target as unknown as HTMLScriptElement)?.src &&
				scriptLoadingErrorsToFilterOut.some(
					(filteredUrl) =>
						((originalException as ErrorEvent).target as unknown as HTMLScriptElement).src?.includes(
							filteredUrl
						)
				)
			) {
				return null;
			}

			if (event?.request?.headers?.['User-Agent'].includes('AhrefsBot')) {
				return null;
			}

			const sessionDuration = (event?.extra?.['session:duration'] as number) ?? minimumDurationToSend;

			if (sessionDuration < minimumDurationToSend) {
				return null;
			}

			return event;
		},
		ignoreErrors: [
			'__firefox__.metadata.extractMetadata',
			'__firefox__.favicons.getFavicons',
			'__firefox__.searchQueryForField',
			'SymBrowser_ModifyAnchorTagWithTarget',
			"Cannot read property 'Logger' of undefined",
			'Blocked a frame with origin "https://fundrise.com" from accessing a frame with origin "https://s.amazon-adsystem.com"',
			'Uncaught ReferenceError: ztePageScrollModule is not defined',
			'vid_mate_check',
			'PADDINGPADDINGXX',
			'__gCrWeb.autofill.extractForms',
			'fuckAdBlock',
			'blockAdBlock',
			'get_mSecure_Captured_Username',
			'_isMatchingDomain is not defined',
			'l.LegacyGlobal.should_do_lastpass_here is not a function.',
			'UET is not defined',
			'ttd_dom_ready',
			'ttd_dom_ready is not defined',
			'Network request failed',
			'Failed to fetch',
			'NetworkError',
			'Network Error',
			'The play method is not allowed by the user agent.',
			'window.mraidbridge',
			'webkit.messageHandlers',
			"'UET' is not defined",
			"Can't find variable: mraid",
			'timeout of 0ms exceeded',
			'/Blocked a frame with origin/',
			'Cannot find function isEqualNode in object',
			'Object Not Found Matching Id',
			'acsbJS',
			"'acsbJS'",
			'acsb',
			'acsbapp.com',
			'acsbChildFrameReady',
			'_isSmallFakeHeading',
			/.*_isSmallFakeHeading.*/i,
			'_createForOfIteratorHelper',
			'Symbol(Symbol.iterator)',
			'cancelled',
			'InvalidStateError: The object is in an invalid state.',
			'Request aborted',
			'Non-Error exception captured',
			'Non-Error promise rejection captured',
			"'fetch' is undefined",
			'reCaptcha has not been loaded yet.',
			"undefined is not an object (evaluating 'window.grecaptchaV2.ready')",
			"Cannot read properties of undefined (reading 'ready')",
			"Cannot read property 'ready' of undefined",
			'window.grecaptchaV2 is undefined',
			'Loading chunk',
			'Loading CSS chunk',
			'ChunkLoadError',
			'Timeout',
			'NotAllowedError',
			'The request is not allowed',
			"Cannot read properties of undefined (reading 'sendEvents')",
			'ping',
			"getElementsByTagName('video')",
			'fetch',
			'sendEvents'
		],
		denyUrls: [
			/d\.impactradius-event\.com/i,
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
			/maps\.googleapis\.com\/maps\/api/i,
			/gstatic\.com\/recaptcha\/releases/i,
			/@safari-extension/i,
			/@safari-web-extension/i
		]
	});
	setTag('Application', 'Vue');
}
