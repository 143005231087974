<template>
	<q-layout view="lHh Lpr lff">
		<q-page-container>
			<a
				href="#main-content"
				class="sr-only focus:not-sr-only"
				>Skip to main content</a
			>
			<router-view name="header" />
			<router-view />
			<router-view name="footer" />
		</q-page-container>
	</q-layout>
</template>

<script setup lang="ts">
import { useFavicon, usePreferredDark } from '@vueuse/core';
import { notifyWithMessage } from '@shared/utils/get-notify-opts';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@store/app-store';
import { useQuasar } from 'quasar';
import { watchEffect } from 'vue';
import type { Alert } from 'types/alerts';

const quasar = useQuasar();
const appStore = useAppStore();
const isDark = usePreferredDark();
const icon = useFavicon();

if (isDark.value) {
	icon.value = '/favicon-dark.png';
} else {
	icon.value = '/favicon-light.png';
}

const { alerts } = storeToRefs(appStore);

watchEffect(() => {
	alerts?.value.forEach((alert: Alert) => {
		quasar.notify(notifyWithMessage(alert.type, alert.message, 'top', alert.showDismissButton));
		appStore.dismissAlert(alert);
	});
});
</script>
