import { useUserStore } from '@store/user-store';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export const authGuard = async function (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
): Promise<void> {
	const userStore = useUserStore();

	const goToNext = () => {
		if (userStore.isAuthenticated) {
			userStore.authLoading = false;
			return next();
		} else {
			userStore.userId = null;
			userStore.legalEntityId = null;
			userStore.authLoading = false;
			window.location.href = '/login';
			return next(false);
		}
	};

	if (userStore.authLoading) {
		return new Promise((resolve) => {
			const unsubscribe = userStore.$subscribe((_mutation, state) => {
				if (state.authLoading === false) {
					goToNext();
					unsubscribe();
					resolve();
				}
			});
		});
	} else if (userStore.retrievedUserDetails) {
		goToNext();
	} else {
		try {
			await userStore.getUserDetailsWithoutError();
		} finally {
			goToNext();
		}
	}
};
