import { privateApiRequest } from '@utils/request';
import { useSignUpStore } from '@store/sign-up-store';
import { useUserStore } from '@store/user-store';
import type {
	AutomaticVerificationDetailsResponse,
	AutomaticVerificationIssuerDetailsResponse
} from 'types/api/automatic-verification';

export async function getAutomaticVerificationDetails(): Promise<AutomaticVerificationDetailsResponse> {
	const userStore = useUserStore();
	const response = await privateApiRequest.get(
		`/automatic-verification/legal-entity/${userStore.legalEntityId}/details`
	);
	return response.data;
}

export async function getAutomaticVerificationIssuerDetails(): Promise<AutomaticVerificationIssuerDetailsResponse> {
	const signUpStore = useSignUpStore();
	const response = await privateApiRequest.get(
		`/automatic-verification/legal-entity/${signUpStore.company.legalEntityId}/issuer/details`
	);
	return response.data;
}

export async function resetAutomaticVerificationSelection(): Promise<void> {
	const userStore = useUserStore();
	await privateApiRequest.put(`/automatic-verification/legal-entity/${userStore.legalEntityId}/reset-selection/save`);
}
