import { privateApiRequest } from '@utils/request';
import { useUserStore } from '@store/user-store';
import type { CheckoutDetailsResponse } from 'types/api/checkout';

export async function getFirstTimeCheckoutDetails(): Promise<CheckoutDetailsResponse> {
	const userStore = useUserStore();
	const response = await privateApiRequest.put(
		`/checkout/legal-entity/${userStore.legalEntityId}/first-time/details/save`
	);
	return response.data;
}

export async function saveCheckoutConfirmation(secondaryTransactionId: number): Promise<void> {
	const userStore = useUserStore();
	await privateApiRequest.put(
		`/checkout/legal-entity/${userStore.legalEntityId}/secondary-transaction/${secondaryTransactionId}/save`
	);
}
