import { initAccessibe } from '@utils/accessibility';
import { initDayjs } from '@utils/dayjs';
import { initGoogleTag } from '@utils/google-analytics';
import { initMixpanel } from '@utils/analytics';
import { initSentry } from '@utils/sentry';
import { loadSentryReplay } from '@utils/sentry-replay';
import Logger from 'js-logger';
import { useScriptTag } from '@vueuse/core/index';
import type { App } from '@vue/runtime-core';
import type { Router } from 'vue-router';

export function initializeNonCriticalPathDependencies(app: App<Element>, router: Router) {
	Logger.useDefaults();

	// Sentry
	if (process.env.VITE_SENTRY_ENABLED === 'true') {
		initSentry(app, router)
			.then(() => {
				return loadSentryReplay().catch(() => {
					Logger.error('Sentry Replay failed to load');
				});
			})
			.catch(() => {
				Logger.error('Sentry failed to load');
			});
	}

	useScriptTag('https://acsbapp.com/apps/app/dist/js/app.js', initAccessibe, {
		async: true,
		defer: true
	});

	useScriptTag(
		`https://www.googletagmanager.com/gtag/js?id=${process.env.VITE_GOOGLE_ANALYTICS_MEASURE_ID}`,
		initGoogleTag,
		{
			async: true,
			defer: true
		}
	);

	initMixpanel();
	initDayjs();
}
