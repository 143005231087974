import { cache, createClient, definePlugin, fetch as fetchPlugin } from 'villus';
import { redirectToLoginOrShowErrorAlert } from '@shared/utils/interceptors/error-handler';
import { useAppStore } from '@store/app-store';
import type { FetchOptions } from 'villus';

export const cachePlugin = cache();

const errorHandlerPlugin = definePlugin(({ afterQuery }) => {
	afterQuery(({ error }) => {
		if (error) {
			redirectToLoginOrShowErrorAlert(error);
		}
	});
});

export function getVillusClient() {
	return createClient({
		url: '/private-api/graphql',
		use: [
			function authPlugin({ opContext }: { opContext: FetchOptions }) {
				const appStore = useAppStore();
				opContext.headers[appStore.csrfHeaderName] = appStore.csrfToken;
			},
			cachePlugin,
			errorHandlerPlugin,
			fetchPlugin()
		]
	});
}
