import { useAppStore } from '@store/app-store';
import type { InternalAxiosRequestConfig } from 'axios';

export function csrfInterceptor(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
	const appStore = useAppStore();
	if (config.headers && appStore.csrfHeaderName && appStore.csrfToken) {
		config.headers[appStore.csrfHeaderName] = appStore.csrfToken;
	}
	return config;
}
