import type { OSTypeEnum, ScreenSizeTypeEnum } from '../types/client';

export const Breakpoints = {
	tabletStart: 768,
	desktopStart: 1200
};

export function getScreenSize(): ScreenSizeTypeEnum {
	const screenWidth = window.innerWidth;

	if (screenWidth < Breakpoints.tabletStart) {
		return 'MOBILE';
	} else if (screenWidth >= Breakpoints.desktopStart) {
		return 'DESKTOP';
	} else {
		return 'TABLET';
	}
}

export function getOS(): OSTypeEnum | null {
	const userAgent = window.navigator.userAgent;
	const platform = window.navigator.platform;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

	if (macosPlatforms.includes(platform)) {
		if (window.navigator.maxTouchPoints > 1) {
			return 'iOS'; // iPadOS workaround
		}
		return 'MacOS';
	} else if (iosPlatforms.includes(platform)) {
		return 'iOS';
	} else if (windowsPlatforms.includes(platform)) {
		return 'Windows';
	} else if (/Android/.test(userAgent)) {
		return 'Android';
	} else if (/Linux/.test(platform)) {
		return 'Linux';
	} else {
		return null;
	}
}
export function isIphone(): boolean {
	const userAgent = window.navigator.userAgent;
	return userAgent.includes('iPhone') && !userAgent.includes('iPad');
}

export function isMobileOS(): boolean {
	const userAgent = window.navigator.userAgent;
	return isIphone() || userAgent.includes('Android');
}

export function isWebview(): boolean {
	const userAgent = window.navigator.userAgent;
	return userAgent.includes('Fundrise iOS WebView');
}

export function redirect(path: string): void {
	window.location.href = process.env.VUE_APP_BASE_PATH + path;
}

export function replaceRoute(path: string): void {
	const url = process.env.VUE_APP_BASE_PATH + path;
	window.location.replace(url);
}

export function openNewTab(path: string): void {
	window.open(path, '_blank');
}

export function isElementFullyVisible(element: Element): boolean {
	const domElement = element.getBoundingClientRect();
	const html = document.documentElement;
	return (
		domElement.top >= 0 &&
		domElement.left >= 0 &&
		domElement.bottom <= (window.innerHeight || html.clientHeight) &&
		domElement.right <= (window.innerWidth || html.clientWidth)
	);
}
