import { privateApiRequestWithoutErrorAlert } from '@utils/request';
import { useUserStore } from '@store/user-store';

export async function submitAgreementsAcceptance(
	secondaryTransactionId: number,
	documentAcknowledgments: number[],
	acknowledgmentAcceptances: number[]
): Promise<void> {
	const userStore = useUserStore();
	await privateApiRequestWithoutErrorAlert.post(
		`/agreement/legal-entity/${userStore.legalEntityId}/secondary-transaction/${secondaryTransactionId}/submit`,
		{
			documentRevisionIds: documentAcknowledgments,
			acknowledgmentRevisionIds: acknowledgmentAcceptances
		}
	);
}
