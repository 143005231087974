import type { AlertTypeEnum } from 'types/alerts';

export function getNotifyOpts(model: string, action: 'create' | 'update' | 'delete', type: AlertTypeEnum): any {
	let message = '';
	if (type === 'positive') {
		message = `${model} was ${action}d successfully.`;
	} else if (type === 'negative') {
		message = `Unable to ${action} the ${model}.`;
	}
	return notifyWithMessage(type, message);
}

export function notifyWithMessage(
	type: AlertTypeEnum,
	message: string,
	position = 'bottom-left',
	showDismissButton = true
): any {
	return {
		type,
		message,
		position,
		actions: showDismissButton
			? [
					{
						label: 'Dismiss',
						color: 'white'
					}
			  ]
			: []
	};
}

export function emptyFileError(): any {
	return {
		type: 'negative',
		message: 'Your file is empty. You cannot upload an empty file.',
		position: 'bottom-left',
		actions: [
			{
				label: 'Dismiss',
				color: 'white'
			}
		]
	};
}
